<template>
    <div style="margin: 4px;">
        <it-switch type="warning" label="Request Payload Debugging" v-model="globalObject.requestPayloadDebugging" />
    </div>
    <div style="margin: 4px;">
        <it-switch type="warning" label="Response Payload Debugging" v-model="globalObject.responsePayloadDebugging" />
    </div>
    <div style="margin: 4px;">
        <it-switch type="warning" label="Show Category Sequences" v-model="globalObject.showCategorySequences" />
    </div>
</template>

<script>
export default {
    name: 'SettingsPanel',
    props: {
        global: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            globalObject: this.global,
        };
    },
}
</script>

<style scoped>

</style>