<template>
    <div style="display: flex; flex-direction: column; margin: 4px;">

        <it-button @click="createNewCategory()" type="success" style="margin: 8px;" block>Create New Category</it-button>

        <div v-if="showChildCategories">
            <div v-for="(category, categoryIndex) in newCategories" :key="categoryIndex">
                <!-- Spot Divider --> <it-divider />

                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-input status="danger" style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 1em;" prefix="Name" v-model="category.new_name" />
                </div>

                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-input status="danger" style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 1em;" prefix="Keyword" v-model="category.new_keyword" />
                </div>

                <div style="margin: 4px;">
                    <it-switch type="danger" label="Visible" v-model="category.new_display" />
                </div>
                
                <div style="margin: 4px;">
                    <it-checkbox type="danger" label="Allow Google Results" v-model="category.new_source" />
                </div>
                
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-tooltip content="Remove Parent" placement="left">
                        <it-button v-show="category.new_parent != null" @click="removeNewParent(category)" style="margin: 8px;" icon="remove" round></it-button>
                    </it-tooltip>
                    <it-tooltip content="Change Parent" placement="top">
                        <it-button @click="category.selecting_parent = !category.selecting_parent" type="danger" style="margin: 8px;">
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <it-tag style="max-height: 24px;">Parent</it-tag>
                                <div v-if="category.new_parent != null" style="margin: 8px; text-align: left;">
                                    <p>ID: {{category.new_parent.id}}</p>
                                    <p>Name: {{category.new_parent.name}}</p>
                                    <p>Keyword: {{category.new_parent.keyword}}</p>
                                    <p>Visible: {{category.new_parent.display == "hidden" ? "No" : "Yes"}}</p>
                                    <p>Allow Google Results: {{category.new_parent.source == "internal_and_google" ? "Yes" : "No"}}</p>
                                </div>
                                <div v-else style="margin: 8px; text-align: left;"><p>None</p></div>
                            </div>
                        </it-button>
                    </it-tooltip>

                    <div v-if="category.selecting_parent">
                        <it-modal v-model="category.selecting_parent" width="1000px">
                            <template #body>
                                <CategoryParentSelector
                                    :global="global"
                                    v-model="category.new_parent"
                                    :newCategory="true"
                                    :allNewCategories="allNewCategories"
                                    :allCategories="globalObject.categories"
                                    :allLinkedCategories="allLinkedCategories"
                                    :newCategories="allNewCategories"
                                    :categories="globalObject.categories"
                                    :category="category"
                                ></CategoryParentSelector>
                            </template>
                        </it-modal>
                    </div>
                </div>

                <div style="margin: 4px;">
                    <it-tooltip content="Remove Pairing" placement="left">
                        <it-button v-show="category.new_default_pairing != null" @click="category.new_default_pairing = null" style="margin: 8px;" icon="remove" round></it-button>
                    </it-tooltip>
                    <it-tooltip content="Change Pairing" placement="top">
                        <it-button @click="category.selecting_pairing = !category.selecting_pairing" type="danger" style="margin: 8px;">
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <it-tag style="max-height: 24px;">Pairing</it-tag>
                                <div v-if="category.new_default_pairing != null" style="margin: 8px; text-align: left;">
                                    <p>ID: {{category.new_default_pairing.id}}</p>
                                    <p>Name: {{category.new_default_pairing.new_name + (category.new_default_pairing.new_name != category.new_default_pairing.name ? (" (" + category.new_default_pairing.name + ")") : "")}}</p>
                                    <p>Keyword: {{category.new_default_pairing.new_keyword + (category.new_default_pairing.new_keyword != category.new_default_pairing.keyword ? (" (" + category.new_default_pairing.keyword + ")") : "")}}</p>
                                    <p>Visible: {{(category.new_default_pairing.new_display ? "Yes" : "No") + ((category.new_default_pairing.new_display != (category.new_default_pairing.display == "default")) ? " (" + (category.new_default_pairing.display == "hidden" ? "No" : "Yes") + ")" : "")}}</p>
                                    <p>Allow Google Results: {{(category.new_default_pairing.new_source ? "Yes" : "No") + ((category.new_default_pairing.new_source != (category.new_default_pairing.source == "internal_and_google")) ? " (" + (category.new_default_pairing.source == "internal_and_google" ? "Yes" : "No") + ")" : "")}}</p>
                                </div>
                                <div v-else style="margin: 8px; text-align: left;"><p>None</p></div>
                            </div>
                        </it-button>
                    </it-tooltip>
                    
                    <div v-if="category.selecting_pairing">
                        <it-modal v-model="category.selecting_pairing" width="1000px">
                            <template #body>
                                <CategoryPairingSelector
                                    :global="global"
                                    v-model="category.new_default_pairing"
                                    :categories="globalObject.categories"
                                    :category="category"
                                ></CategoryPairingSelector>
                            </template>
                        </it-modal>
                    </div>
                </div>

                <div style="display: flex; flex-direction: row; align-items: center; margin: 0px;">
                    <it-button @click="removeNewCategory(category)" type="warning" style="margin: 8px;" block :disabled="category.deleting" :loading="category.deleting">{{category.deleting ? "Removing" : "Remove"}}</it-button>
                    <it-button v-if="enableSave" @click="saveNewCategory(parentCategory, category)" type="danger" style="margin: 8px;" block :pulse="!category.saving_changes" :disabled="category.saving_changes" :loading="category.saving_changes">{{category.saving_changes ? "Saving" : "Save"}}</it-button>
                </div>
                
                <div style="display: flex; flex-direction: row; margin: 16px;">
                    <it-button v-if="(category.new_childern.length != 0) || (category.childern.length != 0)" @click="category.show_child_categories = !category.show_child_categories" :type="category.show_child_categories ? 'primary' : 'neutral'">{{category.show_child_categories ? "Hide" : "Show"}}</it-button>
                    <CategoryEditor
                        :global="global"
                        :enableSave="false"
                        :showChildCategories="category.show_child_categories"
                        :allNewCategories="allNewCategories"
                        :allLinkedCategories="allLinkedCategories"
                        :parentCategory="category"
                        :newCategories="category.new_childern"
                        :categories="category.childern"
                        :sections="category.sections"
                        :linkedCategories="category.linked_categories"
                    ></CategoryEditor>
                </div>
            </div>

            <div v-for="(category, categoryIndex) in categories" :key="categoryIndex">
                <div v-if="parentCategory != null" style="min-height: 64px;" @mouseover="category.show_section = true" @mouseleave="category.show_section = false">
                    <div v-show="category.show_section || (sections.find(filterSection => filterSection.displacement == categoryIndex) != null)">
                        <it-divider/>

                        <it-button v-if="sections.find(filterSection => filterSection.displacement == categoryIndex) == null" @click="insertSection(categoryIndex)" type="neutral" style="margin: 8px" round text block>Insert Section</it-button>

                        <div v-if="sections.find(filterSection => filterSection.displacement == categoryIndex) != null" style="display: flex; flex-direction: column; align-items: center;">
                            <div v-if="sections.find(filterSection => filterSection.displacement == categoryIndex).new" style="display: flex; flex-direction: row; align-items: center;">
                                <it-button :disabled="sections.find(filterSection => filterSection.displacement == categoryIndex).saving_changes" :loading="sections.find(filterSection => filterSection.displacement == categoryIndex).saving_changes" @click="saveSection(categoryIndex)" type="danger" style="margin: 16px" round>{{ sections.find(filterSection => filterSection.displacement == categoryIndex).saving_changes ? "Saving Section" : "Save Section" }}</it-button>
                            </div>

                            <it-popover :disabled="sections.find(filterSection => filterSection.displacement == categoryIndex).delete_confirmed" style="margin-right: 8px;">
                                <it-tooltip v-if="sections.find(filterSection => filterSection.displacement == categoryIndex).new" :disabled="categoryIndex == 0" content="Remove Section" placement="top">
                                    <it-tag @close="removeSection(categoryIndex)" type="neutral" :closable="categoryIndex != 0">
                                        <div style="display: flex; flex-direction: row; align-items: center;">
                                            <p>Section Depth: {{ getSectionDepth(categoryIndex) }}</p>
                                        </div>
                                    </it-tag>
                                </it-tooltip>
                                <it-tooltip v-else :disabled="!sections.find(filterSection => filterSection.displacement == categoryIndex).delete_confirmed || categoryIndex == 0" :content="sections.find(filterSection => filterSection.displacement == categoryIndex).deleting ? 'Deleting' : 'Delete Section'" placement="top">
                                    <it-tag @close="sections.find(filterSection => filterSection.displacement == categoryIndex).delete_confirmed = false" type="neutral" :closable="categoryIndex != 0 && !sections.find(filterSection => filterSection.displacement == categoryIndex).deleting">
                                        <div style="display: flex; flex-direction: row; align-items: center;">
                                            <p>Section Depth: {{ getSectionDepth(categoryIndex) }}</p>
                                            <it-loading v-if="sections.find(filterSection => filterSection.displacement == categoryIndex).deleting" style="margin-left: 8px;" stroke="1" radius="5" color="#000000"></it-loading>
                                        </div>
                                    </it-tag>
                                </it-tooltip>

                                <template #content>
                                    <div>
                                        <p style="margin-bottom: 8px">Are you sure?</p>
                                        <div style="display: flex; justify-content: flex-end">
                                            <it-button @click="sections.find(filterSection => filterSection.displacement == categoryIndex).delete_confirmed = true" size="small">No</it-button>
                                            <it-button @click="deleteSection(categoryIndex)" size="small" style="margin-left: 8px" type="danger">Yes</it-button>
                                        </div>
                                    </div>
                                </template>
                            </it-popover>
                            <it-icon name="expand_more" outlined />
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-tooltip :content="category.show_info ? 'Hide Info' : 'Show Info'" placement="top">
                        <it-button @click="category.show_info = !category.show_info" :type="category.show_info ? 'primary' : 'neutral'" style="margin: 8px" :icon="category.show_info ? 'expand_less' : 'expand_more'" round text></it-button>
                    </it-tooltip>
                    <it-input :disabled="!category.show_info" :status="(category.new_name != category.name) ? 'danger' : 'neutral'" style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 1em;" prefix="Name" v-model="category.new_name" :placeholder="category.name" />
                    <it-tooltip v-show="category.show_info" content="Revert" placement="right">
                        <it-button v-show="category.new_name != category.name" @click="category.new_name = category.name" style="margin: 8px" type="danger" icon="undo" round />
                    </it-tooltip>
                    
                    <div v-show="!category.show_info" style="display: flex; flex-direction: row; align-items: center;">
                        <it-button :disabled="categoryIndex == 0" @click="moveCategory(category, category.new_sequence - 1)" :type="category.new_sequence > category.sequence ? 'danger' : 'neutral'" style="margin: 8px" icon="arrow_upward" round>Move Up</it-button>
                        <it-button :disabled="categoryIndex == lastCategoryIndex" @click="moveCategory(category, category.new_sequence + 1)" :type="category.new_sequence < category.sequence ? 'danger' : 'neutral'" style="margin: 8px" icon="arrow_downward" round>Move Down</it-button>
                    </div>
                </div>

                <div v-show="category.show_info">
                    <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                        <it-input :status="(category.new_keyword != category.keyword) ? 'danger' : 'neutral'" style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 1em;" prefix="Keyword" v-model="category.new_keyword" :placeholder="category.keyword" />
                        <it-tooltip content="Revert" placement="right">
                            <it-button v-show="category.new_keyword != category.keyword" @click="category.new_keyword = category.keyword" style="margin: 8px" type="danger" icon="undo" round />
                        </it-tooltip>
                    </div>
    
                    <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                        <it-switch :type="((category.new_display ? 'default' : 'hidden') != category.display) ? 'danger' : 'success'" label="Visible" v-model="category.new_display" />
                        <it-tooltip content="Revert" placement="right">
                            <it-button v-show="(category.new_display ? 'default' : 'hidden') != category.display" @click="category.new_display = category.display" style="margin: 8px" type="danger" icon="undo" round />
                        </it-tooltip>
                    </div>
    
                    <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                        <it-checkbox :type="((category.new_source ? 'internal_and_google' : 'internal') != category.source) ? 'danger' : 'success'" label="Allow Google Results" v-model="category.new_source" />
                        <it-tooltip content="Revert" placement="right">
                            <it-button v-show="(category.new_source ? 'internal_and_google' : 'internal') != category.source" @click="category.new_source = category.source" style="margin: 8px" type="danger" icon="undo" round />
                        </it-tooltip>
                    </div>

                    <it-tag style="margin: 8px">
                        <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                            <it-tooltip content="Remove Parent" placement="left">
                                <it-button v-show="category.new_parent != null" @click="removeParent(category)" style="margin: 8px;" icon="remove" round></it-button>
                            </it-tooltip>
                            <it-tooltip content="Change Parent" placement="top">
                                <it-button @click="category.selecting_parent = !category.selecting_parent" :type="category.new_parent != category.parent ? 'danger' : (category.parent != null ? 'success' : 'neutral')" style="margin: 8px;">
                                    <div style="display: flex; flex-direction: row; align-items: center;">
                                        <it-tag style="max-height: 24px;">Parent</it-tag>
                                        <div v-if="category.new_parent != null" style="margin: 8px; text-align: left;">
                                            <p>ID: {{category.new_parent.id}}</p>
                                            <p>Name: {{category.new_parent.new_name + (category.new_parent.new_name != category.new_parent.name ? (" (" + category.new_parent.name + ")") : "")}}</p>
                                            <p>Keyword: {{category.new_parent.new_keyword + (category.new_parent.new_keyword != category.new_parent.keyword ? (" (" + category.new_parent.keyword + ")") : "")}}</p>
                                            <p>Visible: {{(category.new_parent.new_display ? "Yes" : "No") + ((category.new_parent.new_display != (category.new_parent.display == "default")) ? " (" + (category.new_parent.display == "hidden" ? "No" : "Yes") + ")" : "")}}</p>
                                            <p>Allow Google Results: {{(category.new_parent.new_source ? "Yes" : "No") + ((category.new_parent.new_source != (category.new_parent.source == "internal_and_google")) ? " (" + (category.new_parent.source == "internal_and_google" ? "Yes" : "No") + ")" : "")}}</p>
                                        </div>
                                        <div v-else style="margin: 8px; text-align: left;"><p>None</p></div>
                                    </div>
                                </it-button>
                            </it-tooltip>

                            <it-tag v-if="global.showCategorySequences" style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 1em;">Sequence: {{ category.sequence }}</it-tag>

                            <it-button :disabled="categoryIndex == 0" @click="moveCategory(category, category.new_sequence - 1)" :type="category.new_sequence > category.sequence ? 'danger' : 'neutral'" style="margin: 8px" icon="arrow_upward" round>Move Up</it-button>
                            <it-button :disabled="categoryIndex == lastCategoryIndex" @click="moveCategory(category, category.new_sequence + 1)" :type="category.new_sequence < category.sequence ? 'danger' : 'neutral'" style="margin: 8px" icon="arrow_downward" round>Move Down</it-button>

                            <it-tooltip content="Revert" placement="right">
                                <it-button v-show="(category.new_parent != category.parent) || (category.new_sequence != category.sequence)" @click="revertParentAndSequence(category)" style="margin: 8px" type="danger" icon="undo" round />
                            </it-tooltip>
                            
                            <div v-if="category.selecting_parent">
                                <it-modal v-model="category.selecting_parent" width="1000px">
                                    <template #body>
                                        <CategoryParentSelector
                                            :global="global"
                                            v-model="category.new_parent"
                                            :allCategories="globalObject.categories"
                                            :allLinkedCategories="allLinkedCategories"
                                            :categories="globalObject.categories"
                                            :category="category"
                                        ></CategoryParentSelector>
                                    </template>
                                </it-modal>
                            </div>
                        </div>
                    </it-tag>

                    <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                        <it-tooltip content="Remove Pairing" placement="left">
                            <it-button v-show="category.new_default_pairing != null" @click="category.new_default_pairing = null" style="margin: 8px;" icon="remove" round></it-button>
                        </it-tooltip>
                        <it-tooltip content="Change Pairing" placement="top">
                            <it-button @click="category.selecting_pairing = !category.selecting_pairing" :type="pairingChanged(category) ? 'danger' : (category.default_pairing != null ? 'success' : 'neutral')" style="margin: 8px;">
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <it-tag style="max-height: 24px;">Pairing</it-tag>
                                    <div v-if="category.new_default_pairing != null" style="margin: 8px; text-align: left;">
                                        <p>ID: {{category.new_default_pairing.id}}</p>
                                        <p>Name: {{category.new_default_pairing.new_name + (category.new_default_pairing.new_name != category.new_default_pairing.name ? (" (" + category.new_default_pairing.name + ")") : "")}}</p>
                                        <p>Keyword: {{category.new_default_pairing.new_keyword + (category.new_default_pairing.new_keyword != category.new_default_pairing.keyword ? (" (" + category.new_default_pairing.keyword + ")") : "")}}</p>
                                        <p>Visible: {{(category.new_default_pairing.new_display ? "Yes" : "No") + ((category.new_default_pairing.new_display != (category.new_default_pairing.display == "default")) ? " (" + (category.new_default_pairing.display == "hidden" ? "No" : "Yes") + ")" : "")}}</p>
                                        <p>Allow Google Results: {{(category.new_default_pairing.new_source ? "Yes" : "No") + ((category.new_default_pairing.new_source != (category.new_default_pairing.source == "internal_and_google")) ? " (" + (category.new_default_pairing.source == "internal_and_google" ? "Yes" : "No") + ")" : "")}}</p>
                                    </div>
                                    <div v-else style="margin: 8px; text-align: left;"><p>None</p></div>
                                </div>
                            </it-button>
                        </it-tooltip>
    
                        <it-tooltip content="Revert" placement="right">
                            <it-button v-show="pairingChanged(category)" @click="category.new_default_pairing = category.default_pairing" style="margin: 8px" type="danger" icon="undo" round />
                        </it-tooltip>
    
                        <div v-if="category.selecting_pairing">
                            <it-modal v-model="category.selecting_pairing" width="1000px">
                                <template #body>
                                    <CategoryPairingSelector
                                        :global="global"
                                        v-model="category.new_default_pairing"
                                        :categories="globalObject.categories"
                                        :category="category"
                                    ></CategoryPairingSelector>
                                </template>
                            </it-modal>
                        </div>
                    </div>
    
                    <div style="display: flex; flex-direction: row; align-items: center; margin: 0px;">
                        <it-popover :disabled="category.delete_confirmed" style="margin-right: 8px;">
                            <it-button @click="category.delete_confirmed = !category.delete_confirmed" type="warning" style="margin: 8px;" block :disabled="category.deleting" :loading="category.deleting">{{category.deleting ? "Deleting" : "Delete"}}</it-button>
                            <template #content>
                                <div>
                                    <p style="margin-bottom: 8px">Are you sure?</p>
                                    <div style="display: flex; justify-content: flex-end">
                                        <it-button @click="category.delete_confirmed = true" size="small">No</it-button>
                                        <it-button @click="deleteCategory(category)" size="small" style="margin-left: 8px" type="danger">Yes</it-button>
                                    </div>
                                </div>
                            </template>
                        </it-popover>
    
                        <it-button @click="saveCategory(categoryIndex)" :type="categoryChanged(category) ? 'danger' : 'neutral'" style="margin: 8px;" block :pulse="categoryChanged(category)" :loading="category.saving_changes" :disabled="category.saving_changes || !categoryChanged(category)">{{category.saving_changes ? "Saving" : (categoryChanged(category) ? "Save" : "Saved")}}</it-button>
                    </div>
    
                    <div style="display: flex; flex-direction: row; margin: 16px;">
                        <it-button v-if="(category.new_childern.length != 0) || (category.childern.length != 0)" @click="category.show_child_categories = !category.show_child_categories" :type="category.show_child_categories ? 'primary' : 'neutral'">{{category.show_child_categories ? "Hide" : "Show"}}</it-button>
                        <CategoryEditor
                            :global="global"
                            :showChildCategories="category.show_child_categories"
                            :allNewCategories="allNewCategories"
                            :allLinkedCategories="allLinkedCategories"
                            :parentCategory="category"
                            :newCategories="category.new_childern"
                            :categories="category.childern"
                            :sections="category.sections"
                            :linkedCategories="category.linked_categories"
                        ></CategoryEditor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryParentSelector from './CategoryParentSelector.vue';
import CategoryPairingSelector from './CategoryPairingSelector.vue';

export default {
    name: "CategoryEditor",
    components: {
        CategoryParentSelector,
        CategoryPairingSelector
    },
    props: {
        global: {
            type: Object,
            required: true,
        },
        enableSave: {
            type: Boolean,
            required: false,
            default: true,
        },
        parentCategory: {
            type: Object,
            required: false,
            default: null,
        },
        showChildCategories: {
            type: Boolean,
            required: true,
        },
        newCategories: {
            type: Array,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        sections: {
            type: Array,
            required: true,
        },
        linkedCategories: {
            type: Array,
            required: true,
        },
        allNewCategories: {
            type: Array,
            required: true,
        },
        allLinkedCategories: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            globalObject: this.global,
            categoriesArray: this.categories,
            newCategoriesArray: this.newCategories,
            sectionsArray: this.sections,
            linkedCategoriesArray: this.linkedCategories,
            allNewCategoriesArray: this.allNewCategories,
            allLinkedCategoriesArray: this.allLinkedCategories,
        };
    },
    computed: {
        lastNewCategoryIndex: {
            get(){
                return (this.newCategories.length - 1);
            }
        },
        lastCategoryIndex: {
            get(){
                return (this.categories.length - 1);
            }
        },
    },
    methods: {
        insertSection(sectionDisplacement){
            this.sectionsArray.push({
                displacement: sectionDisplacement,
                new: true,
                saving_changes: false,
                delete_confirmed: true,
                deleting: false,
            });
            
            this.sectionsArray.sort((section0, section1) => section0.displacement - section1.displacement);
        },
        saveSection(sectionDisplacement){
            const section = this.sections.find(filterSection => filterSection.displacement == sectionDisplacement);
            section.saving_changes = true;

            const requestURL = this.global.domain + this.global.categoryEndpoint + "/" + this.parentCategory.id + this.global.sectionEndpoint + "/" + section.displacement;
            const requestMethod = "PUT";
            const requestIndex = this.globalObject.requestCount++;

            this.globalObject.logs.push({
                type: "primary",
                title: "CategoryEditor.saveSection(" + sectionDisplacement + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                body: "",
            });

            fetch(requestURL, {
                method: requestMethod,
            }).then((response) => response.json()).then((responseJSON) => {
                if (responseJSON.success) {
                    if(this.global.responsePayloadDebugging){
                        this.$Message({text: "Response Payload: " + JSON.stringify(responseJSON)});
                    }

                    this.globalObject.logs.push({
                        type: "primary",
                        title: "CategoryEditor.saveSection(" + sectionDisplacement + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });

                    section.new = false;
                    section.saving_changes = false;
                }
                else {
                    section.saving_changes = false;

                    this.$Notification.danger({
                        duration: 10000,
                        title: "Failed to save section " + section.displacement + ".",
                        text: responseJSON.error.message
                    });
                    this.globalObject.logs.push({
                        type: "danger",
                        title: "CategoryEditor.saveSection(" + sectionDisplacement + "); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });
                }
            });
        },
        removeSection(sectionDisplacement){
            const sections = this.sections.filter(filterSection => filterSection.displacement != sectionDisplacement);
            this.sectionsArray.length = 0;
            this.sectionsArray.push(...sections);
        },
        getSectionDepth(sectionDisplacement){
            return this.sections.indexOf(this.sections.find(filterSection => filterSection.displacement == sectionDisplacement));
        },
        deleteSection(sectionDisplacement){
            const section = this.sections.find(filterSection => filterSection.displacement == sectionDisplacement);
            section.delete_confirmed = true;
            section.deleting = true;

            const requestURL = this.global.domain + this.global.categoryEndpoint + "/" + this.parentCategory.id + this.global.sectionEndpoint + "/" + section.displacement;
            const requestMethod = "DELETE";
            const requestIndex = this.globalObject.requestCount++;

            this.globalObject.logs.push({
                type: "primary",
                title: "CategoryEditor.deleteSection(" + sectionDisplacement + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                body: "",
            });

            fetch(requestURL, {
                method: requestMethod,
            }).then((response) => response.json()).then((responseJSON) => {
                if (responseJSON.success) {
                    if(this.global.responsePayloadDebugging){
                        this.$Message({text: "Response Payload: " + JSON.stringify(responseJSON)});
                    }

                    this.globalObject.logs.push({
                        type: "primary",
                        title: "CategoryEditor.deleteSection(" + sectionDisplacement + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });

                    const sections = this.sections.filter(filterSection => filterSection.displacement != section.displacement);
                    this.sectionsArray.length = 0;
                    this.sectionsArray.push(...sections);
                }
                else {
                    section.deleting = false;

                    this.$Notification.danger({
                        duration: 10000,
                        title: "Failed to delete section " + section.displacement + ".",
                        text: responseJSON.error.message
                    });
                    this.globalObject.logs.push({
                        type: "danger",
                        title: "CategoryEditor.deleteSection(" + sectionDisplacement + "); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });
                }
            });
        },
        moveCategory(category, newSequence){
            const categories = category.new_parent == null ? this.globalObject.categories : category.new_parent.childern;

            const displacement = newSequence - category.new_sequence;
            const displacementDirection = displacement < 0 ? -1 : 1;
            const categoryIndexLimit = newSequence + displacementDirection;

            if(displacement < 0){
                for(let categoryIndex = category.new_sequence + displacementDirection; categoryIndexLimit < categoryIndex; categoryIndex += displacementDirection){
                    const movedCategory = categories[categoryIndex];
                    movedCategory.new_sequence -= displacementDirection;
                    categories[movedCategory.new_sequence] = movedCategory;
                }
            } else {
                for(let categoryIndex = category.new_sequence + displacementDirection; categoryIndex < categoryIndexLimit; categoryIndex += displacementDirection){
                    const movedCategory = categories[categoryIndex];
                    movedCategory.new_sequence -= displacementDirection;
                    categories[movedCategory.new_sequence] = movedCategory;
                }
            }

            categories[newSequence] = category;
            category.new_sequence = newSequence;
        },
        removeNewParent(category){
            const newCategories = category.new_parent.new_childern.filter(filterCategory => filterCategory != category);
            category.new_parent.new_childern.length = 0;
            category.new_parent.new_childern.push(...newCategories);
            
            if ((category.new_parent.new_childern.length == 0) && (category.new_parent.childern.length == 0)) {
                category.new_parent.show_child_categories = false;
            }

            category.new_parent = null;

            this.allNewCategoriesArray.unshift(category);
        },
        removeParent(category){
            const categories = category.new_parent.childern.filter(filterCategory => filterCategory != category);
            category.new_parent.childern.length = 0;
            category.new_parent.childern.push(...categories);
            
            if(category.parent == category.new_parent){
                category.parent.linked_childern.unshift(category);
            }

            const newParentChildCategoryCount = category.new_parent.childern.length;
            if ((category.new_parent.new_childern.length == 0) && (newParentChildCategoryCount == 0)) {
                category.new_parent.show_child_categories = false;
            } else {
                for(let categoryIndex = category.new_sequence; categoryIndex != newParentChildCategoryCount; ++categoryIndex){
                    --category.new_parent.childern[categoryIndex].new_sequence;
                }
            }

            category.new_parent = null;

            const categoryCount = this.globalObject.categories.length;
            for(let categoryIndex = category.sequence; categoryIndex != categoryCount; ++categoryIndex){
                ++this.globalObject.categories[categoryIndex].new_sequence;
            }

            this.globalObject.categories.unshift(category);

            if(category.parent == null){
                const categories = this.allLinkedCategories.filter(filterCategory => filterCategory != category);
                this.allLinkedCategoriesArray.length = 0;
                this.allLinkedCategoriesArray.push(...categories);

                if(category.sequence != 0){
                    category.new_sequence = 0;
                    this.moveCategory(category, category.sequence);
                }
                category.new_sequence = category.sequence;
            } else {
                category.new_sequence = 0;
            }
        },
        revertParentAndSequence(category){
            if(category.new_parent != null){
                const categories = category.new_parent.childern.filter(filterCategory => filterCategory != category);
                category.new_parent.childern.length = 0;
                category.new_parent.childern.push(...categories);

                const newParentChildCategoryCount = category.new_parent.childern.length;
                if ((category.new_parent.new_childern.length == 0) && (newParentChildCategoryCount == 0)) {
                    category.new_parent.show_child_categories = false;
                } else {
                    for(let categoryIndex = category.new_sequence; categoryIndex != newParentChildCategoryCount; ++categoryIndex){
                        --category.new_parent.childern[categoryIndex].new_sequence;
                    }
                }
            } else {
                const categories = this.globalObject.categories.filter(filterCategory => filterCategory != category);
                this.globalObject.categories.length = 0;
                this.globalObject.categories.push(...categories);
                
                const categoryCount = this.globalObject.categories.length;
                for(let categoryIndex = category.new_sequence; categoryIndex != categoryCount; ++categoryIndex){
                    --this.globalObject.categories[categoryIndex].new_sequence;
                }
            }

            category.new_parent = category.parent;

            if(category.parent == null){
                const categories = this.allLinkedCategories.filter(filterCategory => filterCategory != category);
                this.allLinkedCategoriesArray.length = 0;
                this.allLinkedCategoriesArray.push(...categories);

                const categoryCount = this.globalObject.categories.length;
                for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                    ++this.globalObject.categories[categoryIndex].new_sequence;
                }

                this.globalObject.categories.unshift(category);
            } else {
                const categories = category.parent.linked_childern.filter(filterCategory => filterCategory != category);
                category.parent.linked_childern.length = 0;
                category.parent.linked_childern.push(...categories);

                const categoryCount = category.parent.childern.length;
                for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                    ++category.parent.childern[categoryIndex].new_sequence;
                }

                category.parent.childern.unshift(category);
            }

            if(category.sequence != 0){
                category.new_sequence = 0;
                this.moveCategory(category, category.sequence);
            }
            category.new_sequence = category.sequence;
        },
        createNewCategory() {
            const parentCategory = this.parentCategory;
            
            this.newCategoriesArray.unshift({
                parent: null,
                sequence: 0,
                name: "",
                keyword: "",
                display: "default",
                source: "internal_and_google",
                default_pairing: null,
                childern: [],
                sections: [],
                linked_childern: [],
                new_parent: parentCategory,
                new_sequence: 0,
                new_name: "",
                new_keyword: "",
                new_display: "default",
                new_source: "internal_and_google",
                new_default_pairing: null,
                new_childern: [],
                new_sections: [],
                show_section: false,
                show_info: true,
                show_child_categories: false,
                selecting_parent: false,
                selecting_pairing: false,
                show_selector_child_categories: false,
                saving_changes: false,
                delete_confirmed: true,
                deleting: false,
            });

            if (parentCategory != null) {
                parentCategory.show_child_categories = true;
            }
        },
        constructCategory(category) {
            const categoryCount = category.new_childern.length;
            category.childern.length = categoryCount;
            for (let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex) {
                category.childern[categoryIndex] = this.constructCategory(category.new_childern[categoryIndex]);
            }

            const sectionCount = category.new_sections.length;
            category.sections.length = sectionCount;
            for(let sectionIndex = 0; sectionIndex != sectionCount; ++sectionIndex){
                category.sections[sectionIndex] = category.sections[sectionIndex].new_displacement;
            }

            return {
                parent: category.new_parent == null ? null : {
                    id: category.new_parent.id,
                },
                sequence: category.new_sequence,
                name: category.new_name,
                keyword: category.new_keyword,
                display: category.new_display ? "default" : "hidden",
                sections: category.sections,
                source: category.new_source ? "internal_and_google" : "internal",
                default_pairing: (category.new_default_pairing == null) ? null : {
                    id: category.new_default_pairing.id,
                },
                childern: category.childern,
            };
        },
        constructNewCategory(parentCategory, category) {
            const categoryCount = category.childern.length;
            for (let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex) {
                category.childern[categoryIndex] = this.constructNewCategory(category, category.childern[categoryIndex]);
            }

            const sectionCount = category.sections.length;
            for(let sectionIndex = 0; sectionIndex != sectionCount; ++sectionIndex){
                const sectionDisplacement = category.sections[sectionIndex];
                category.sections[sectionIndex] = {
                    displacement: sectionDisplacement,
                    new_displacement: sectionDisplacement,
                    saving_changes: false,
                    delete_confirmed: true,
                    deleting: false,
                };
            }

            return {
                ...category,
                parent: parentCategory,
                linked_childern: [],
                new_parent: parentCategory,
                new_sequence: category.sequence,
                new_name: category.name,
                new_keyword: category.keyword,
                new_display: category.display == "default",
                new_source: category.source == "internal_and_google",
                new_default_pairing: category.default_pairing,
                new_childern: [],
                new_sections: [],
                show_section: false,
                show_info: false,
                show_child_categories: false,
                selecting_parent: false,
                selecting_pairing: false,
                show_selector_child_categories: false,
                saving_changes: false,
                delete_confirmed: true,
                deleting: false,
            };
        },
        removeNewCategory(category) {
            const newCategories = this.newCategoriesArray.filter(filterCategory => filterCategory != category);
            this.newCategoriesArray.length = 0;
            this.newCategoriesArray.push(...newCategories);

            category.new_parent = null;
            category.parent = null;

            category.new_default_pairing = null;
            category.default_pairing = null;

            const parentCategory = this.parentCategory;
            if ((parentCategory != null) && (this.newCategories.length == 0) && (this.categories.length == 0)) {
                parentCategory.show_child_categories = false;
            }
        },
        saveNewCategory(parentCategory, category) {
            category.saving_changes = true;

            let payload = this.constructCategory(category);

            const payloadJSON = JSON.stringify(payload);

            const requestURL = this.global.domain + this.global.categoryEndpoint;
            const requestMethod = "POST";
            const requestIndex = this.globalObject.requestCount++;

            if(this.global.requestPayloadDebugging){
                this.$Message({text: "Request Payload: " + payloadJSON});
            }

            this.globalObject.logs.push({
                type: "primary",
                title: "CategoryEditor.saveNewCategory(" + (parentCategory != null ? "category:Object, " : "null, ") + (category != null ? "category:Object" : "null") + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                body: "Request Payload: " + JSON.stringify(payload, null, '\t'),
            });

            fetch(requestURL, {
                method: requestMethod,
                headers: {
                    "Content-Type": "application/json"
                },
                body: payloadJSON,
            }).then((response) => response.json()).then((responseJSON) => {
                if (responseJSON.success) {
                    if(this.global.responsePayloadDebugging){
                        this.$Message({text: "Response Payload: " + JSON.stringify(responseJSON)});
                    }

                    this.globalObject.logs.push({
                        type: "primary",
                        title: "CategoryEditor.saveNewCategory(" + (parentCategory != null ? "category:Object, " : "null, ") + (category != null ? "category:Object" : "null") + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });

                    const newCategory = this.constructNewCategory(parentCategory, responseJSON.data);

                    if (parentCategory == null) {
                        const newCategories = this.newCategoriesArray.filter(filterCategory => filterCategory != category);
                        this.newCategoriesArray.length = 0;
                        this.newCategoriesArray.push(...newCategories);

                        this.categoriesArray.unshift(newCategory);

                        const categoryCount = this.categories.length;
                        for(let categoryIndex = 1; categoryIndex != categoryCount; ++categoryIndex){
                            const unsequencedCategory = this.categoriesArray[categoryIndex];
                            ++unsequencedCategory.new_sequence;

                            if(unsequencedCategory.parent == unsequencedCategory.new_parent){
                                ++unsequencedCategory.sequence;
                            }
                        }
                    } else {
                        const newCategories = parentCategory.new_childern.filter(filterCategory => filterCategory != category);
                        parentCategory.new_childern.length = 0;
                        parentCategory.new_childern.push(...newCategories);

                        parentCategory.childern.unshift(newCategory);

                        const categoryCount = parentCategory.childern.length;
                        for(let categoryIndex = 1; categoryIndex != categoryCount; ++categoryIndex){
                            const unsequencedCategory = parentCategory.childern[categoryIndex];
                            ++unsequencedCategory.new_sequence;

                            if(unsequencedCategory.parent == unsequencedCategory.new_parent){
                                ++unsequencedCategory.sequence;
                            }
                        }
                    }

                    for(const newChildCategoryIndex in category.new_childern){
                        this.saveNewCategory(newCategory, category.new_childern[newChildCategoryIndex]);
                    }
                }
                else {
                    category.saving_changes = false;

                    if(!this.requestPayloadDebugging){
                        this.$Message.danger({
                            duration: 10000,
                            text: payloadJSON
                        });
                    }
                    this.$Notification.danger({
                        duration: 10000,
                        title: "Failed to create '" + category.new_name + "' category.",
                        text: responseJSON.error.message
                    });
                    this.globalObject.logs.push({
                        type: "danger",
                        title: "CategoryEditor.saveNewCategory(" + (parentCategory != null ? "category:Object, " : "null, ") + (category != null ? "category:Object" : "null") + "); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });
                }
            });
        },
        pairingChanged(category){
            if((category.new_default_pairing != null) && (category.default_pairing != null)){
                return category.new_default_pairing.id != category.default_pairing.id;
            } else {
                return category.new_default_pairing != category.default_pairing;
            }
        },
        categoryChanged(category) {
            return (category.new_parent != category.parent)
                || (category.new_sequence != category.sequence)
                || (category.new_name != category.name)
                || (category.new_keyword != category.keyword)
                || ((category.new_display ? "default" : "hidden") != category.display)
                || ((category.new_source ? "internal_and_google" : "internal") != category.source)
                || this.pairingChanged(category);
        },
        saveCategory(categoryIndex) {
            const category = this.categories[categoryIndex];
            category.saving_changes = true;

            let payload = {};
            
            if(category.new_parent != category.parent){
                payload = {
                    ...payload,
                    parent: category.new_parent == null ? null : {
                        id: category.new_parent.id,
                    },
                };
            }

            if (category.sequence != category.new_sequence) {
                payload = {
                    ...payload,
                    sequence: category.new_sequence,
                };
            }

            if (category.name != category.new_name) {
                payload = {
                    ...payload,
                    name: category.new_name,
                };
            }
            
            if (category.keyword != category.new_keyword) {
                payload = {
                    ...payload,
                    keyword: category.new_keyword,
                };
            }
            
            const newDisplay = category.new_display ? "default" : "hidden";
            if (newDisplay != category.display) {
                payload = {
                    ...payload,
                    display: newDisplay,
                };
            }
            
            const newSource = category.new_source ? "internal_and_google" : "internal";
            if (newSource != category.source) {
                payload = {
                    ...payload,
                    source: newSource,
                };
            }
            
            if((category.new_default_pairing != null) && (category.default_pairing != null)){
                if(category.new_default_pairing.id != category.default_pairing.id){
                    payload = {
                        ...payload,
                        default_pairing: {
                            id: category.new_default_pairing.id,
                        },
                    };
                }
            } else if(category.new_default_pairing != category.default_pairing){
                payload = {
                    ...payload,
                    default_pairing: category.new_default_pairing == null ? null : {
                        id: category.new_default_pairing.id,
                    },
                };
            }

            const payloadJSON = JSON.stringify(payload);

            const requestURL = this.global.domain + this.global.categoryEndpoint + "/" + category.id;
            const requestMethod = "PUT";
            const requestIndex = this.globalObject.requestCount++;

            if(this.global.requestPayloadDebugging){
                this.$Message({text: "Request Payload: " + payloadJSON});
            }

            this.globalObject.logs.push({
                type: "primary",
                title: "CategoryEditor.saveCategory(" + categoryIndex + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                body: "Request Payload: " + JSON.stringify(payload, null, '\t'),
            });

            fetch(requestURL, {
                method: requestMethod,
                headers: {
                    "Content-Type": "application/json"
                },
                body: payloadJSON,
            }).then((response) => response.json()).then((responseJSON) => {
                if (responseJSON.success) {
                    if(this.global.responsePayloadDebugging){
                        this.$Message({text: "Response Payload: " + JSON.stringify(responseJSON)});
                    }

                    this.globalObject.logs.push({
                        type: "primary",
                        title: "CategoryEditor.saveCategory(" + categoryIndex + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });

                    if(category.parent != category.new_parent){
                        if(category.parent == null){
                            const linkedCategories = this.allLinkedCategories.filter(filterCategory => filterCategory != category);
                            this.allLinkedCategoriesArray.length = 0;
                            this.allLinkedCategoriesArray.push(...linkedCategories);
                            
                            {
                                const categoryCount = linkedCategories.length;
                                for(let index = 0; index != categoryCount; ++index){
                                    const unsequencedCategory = linkedCategories[index];
                                    if(category.sequence < unsequencedCategory.sequence){
                                        --unsequencedCategory.sequence;
                                    }
                                }
                            }
                        } else {
                            const linkedCategories = category.parent.linked_childern.filter(filterCategory => filterCategory != category);
                            category.parent.linked_childern.length = 0;
                            category.parent.linked_childern.push(...linkedCategories);

                            {
                                const categoryCount = category.parent.linked_childern.length;
                                for(let index = 0; index != categoryCount; ++index){
                                    const unsequencedCategory = category.parent.linked_childern[index];
                                    if(category.sequence < unsequencedCategory.sequence){
                                        --unsequencedCategory.sequence;
                                    }
                                }
                            }
                        }

                        if((responseJSON.data.parent != null) && (category.new_parent != null)){
                            if(responseJSON.data.parent.id == category.new_parent.id){
                                category.parent = category.new_parent;
                            }
                        } else if((responseJSON.data.parent == null) && (category.new_parent == null)){
                            category.parent = null;
                        }
                    }

                    if(category.sequence != category.new_sequence){
                        category.sequence = responseJSON.data.sequence;
                    }

                    if(category.name != category.new_name){
                        category.name = responseJSON.data.name;
                    }

                    if(category.keyword != category.new_keyword){
                        category.keyword = responseJSON.data.keyword;
                    }

                    if(category.display != (category.new_display ? "default" : "hidden")){
                        category.display = responseJSON.data.display;
                    }

                    if(category.source != (category.new_source ? "internal_and_google" : "internal")){
                        category.source = responseJSON.data.source;
                    }

                    if(category.default_pairing != category.new_default_pairing){
                        if((responseJSON.data.default_pairing != null) && (category.new_default_pairing != null)){
                            if(responseJSON.data.default_pairing.id == category.new_default_pairing.id){
                                category.default_pairing = category.new_default_pairing;
                            }
                        } else if((responseJSON.data.default_pairing == null) && (category.new_default_pairing == null)){
                            category.default_pairing = null;
                        }
                    }

                    category.saving_changes = false;
                }
                else {
                    category.saving_changes = false;

                    if(!this.requestPayloadDebugging){
                        this.$Message.danger({
                            duration: 10000,
                            text: payloadJSON
                        });
                    }
                    this.$Notification.danger({
                        duration: 10000,
                        title: "Failed to save '" + category.new_name + "' category.",
                        text: responseJSON.error.message
                    });
                    this.globalObject.logs.push({
                        type: "danger",
                        title: "CategoryEditor.saveCategory(" + categoryIndex + "); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });
                }
            });
        },
        deleteCategory(category) {
            category.delete_confirmed = true;
            category.deleting = true;
            
            const requestURL = this.global.domain + this.global.categoryEndpoint + "/" + category.id;
            const requestMethod = "DELETE";
            const requestIndex = this.globalObject.requestCount++;

            fetch(requestURL, {
                method: requestMethod,
            }).then((response) => response.json()).then((responseJSON) => {
                if (responseJSON.success) {
                    if(this.global.responsePayloadDebugging){
                        this.$Message({text: "Response Payload: " + JSON.stringify(responseJSON)});
                    }

                    this.globalObject.logs.push({
                        type: "primary",
                        title: "CategoryEditor.deleteCategory(" + (category != null ? "category:Object" : "null") + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });

                    const categories = this.categories.filter(filterCategory => filterCategory != category);
                    this.categoriesArray.length = 0;
                    this.categoriesArray.push(...categories);
                    
                    {
                        const categoryCount = categories.length;
                        for(let index = 0; index != categoryCount; ++index){
                            const unsequencedCategory = categories[index];
                            --unsequencedCategory.new_sequence;
                            if(category.sequence < unsequencedCategory.sequence){
                                --unsequencedCategory.sequence;
                            }
                        }
                    }

                    if(category.new_parent != category.parent){
                        if(category.parent == null){
                            const linkedCategories = this.allLinkedCategories.filter(filterCategory => filterCategory != category);
                            this.allLinkedCategoriesArray.length = 0;
                            this.allLinkedCategoriesArray.push(...linkedCategories);
                            
                            {
                                const categoryCount = linkedCategories.length;
                                for(let index = 0; index != categoryCount; ++index){
                                    const unsequencedCategory = linkedCategories[index];
                                    if(category.sequence < unsequencedCategory.sequence){
                                        --unsequencedCategory.sequence;
                                    }
                                }
                            }
                        } else {
                            const linkedCategories = category.parent.linked_childern.filter(filterCategory => filterCategory != category);
                            category.parent.linked_childern.length = 0;
                            category.parent.linked_childern.push(...linkedCategories);

                            {
                                const categoryCount = category.parent.linked_childern.length;
                                for(let index = 0; index != categoryCount; ++index){
                                    const unsequencedCategory = category.parent.linked_childern[index];
                                    if(category.sequence < unsequencedCategory.sequence){
                                        --unsequencedCategory.sequence;
                                    }
                                }
                            }
                        }
                    }

                    category.new_parent = null;
                    category.parent = null;

                    category.new_default_pairing = null;
                    category.default_pairing = null;

                    const parentCategory = this.parentCategory;
                    if ((parentCategory != null) && (this.newCategories.length == 0) && (this.categories.length == 0)) {
                        parentCategory.show_child_categories = false;
                    }

                    const categoryCount = this.categories.length;
                    for(let categoryIndex = category.new_sequence; categoryIndex != categoryCount; ++categoryIndex){
                        --this.categoriesArray[categoryIndex].new_sequence;
                    }
                }
                else {
                    category.deleting = false;
                    
                    this.$Notification.danger({
                        duration: 10000,
                        title: "Failed to delete '" + category.name + "' category.",
                        text: responseJSON.error.message
                    });

                    this.globalObject.logs.push({
                        type: "danger",
                        title: "CategoryEditor.deleteCategory(" + (category != null ? "category:Object" : "null") + "); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });
                }
            });
        }
    },
}
</script>

<style scoped>

</style>