import { createApp } from "vue";
import App from "./App.vue";

import Equal from "equal-vue";
import "equal-vue/dist/style.css";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

let vueApp = createApp(App);
vueApp.use(Equal);
vueApp.component("DatePicker", Datepicker);
vueApp.mount("#app");
