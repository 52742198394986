<template>
    <div v-if="newCategory" style="display: flex; flex-direction: column; margin: 4px;">
        <div v-for="(categoryParent, categoryParentIndex) in newCategories" :key="categoryParentIndex">
            <div style="margin: 4px;">
                <it-tooltip :content="categoryParent == category ? 'Cannot Select Itself' : 'Select Parent'" placement="right">
                    <it-button :disabled="categoryParent == category" @click="selectCategory(categoryParent)" :type="categoryParent == categoryObject.parent ? 'success' : (categoryParent == categoryObject.new_parent ? 'danger' : 'neutral')" style="margin: 8px;">
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <it-tag style="max-height: 24px;">Parent</it-tag>
                            <div style="margin: 8px; text-align: left;">
                                <p>Name: {{categoryParent.new_name}}</p>
                                <p>Keyword: {{categoryParent.new_keyword}}</p>
                                <p>Visible: {{categoryParent.new_display == "hidden" ? "No" : "Yes"}}</p>
                                <p>Allow Google Results: {{categoryParent.new_source == "internal_and_google" ? "Yes" : "No"}}</p>
                            </div>
                        </div>
                    </it-button>
                </it-tooltip>
            </div>
            <div v-if="categoryParent != category" style="display: flex; flex-direction: row; margin: 16px;">
                <it-button v-if="categoryParent.childern.length != 0" @click="categoryParent.show_selector_child_categories = !categoryParent.show_selector_child_categories" :type="categoryParent.show_selector_child_categories ? 'primary' : 'neutral'">{{categoryParent.show_selector_child_categories ? "Hide" : "Show"}}</it-button>
                <CategoryParentSelector
                    v-if="categoryParent.show_selector_child_categories"
                    :global="global"
                    v-model="categoryObject.new_parent"
                    :newCategory="newCategory"
                    :allNewCategories="allNewCategories"
                    :newCategories="categoryParent.new_childern"
                    :categories="categoryParent.childern"
                    :category="category"
                ></CategoryParentSelector>
            </div>
            <!-- Spot Divider --> <it-divider v-if="categoryParentIndex != lastNewCategoryIndex" />
        </div>
    </div>

    <div style="display: flex; flex-direction: column; margin: 4px;">
        <div v-for="(categoryParent, categoryParentIndex) in categories" :key="categoryParentIndex">
            <div style="margin: 4px;">
                <it-tooltip :content="categoryParent.id == category.id ? 'Cannot Select Itself' : 'Select Parent'" placement="right">
                    <it-button :disabled="categoryParent.id == category.id" @click="selectCategory(categoryParent)" :type="categoryParent == categoryObject.parent ? 'success' : (categoryParent == categoryObject.new_parent ? 'danger' : 'neutral')" style="margin: 8px;">
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <it-tag style="max-height: 24px;">Parent</it-tag>
                            <div style="margin: 8px; text-align: left;">
                                <p>ID: {{categoryParent.id}}</p>
                                <p>Name: {{categoryParent.new_name + (categoryParent.new_name != categoryParent.name ? (" (" + categoryParent.name + ")") : "")}}</p>
                                <p>Keyword: {{categoryParent.new_keyword + (categoryParent.new_keyword != categoryParent.keyword ? (" (" + categoryParent.keyword + ")") : "")}}</p>
                                <p>Visible: {{(categoryParent.new_display ? "Yes" : "No") + ((categoryParent.new_display != (categoryParent.display == "default")) ? " (" + (categoryParent.display == "hidden" ? "No" : "Yes") + ")" : "")}}</p>
                                <p>Allow Google Results: {{(categoryParent.new_source ? "Yes" : "No") + ((categoryParent.new_source != (categoryParent.source == "internal_and_google")) ? " (" + (categoryParent.source == "internal_and_google" ? "Yes" : "No") + ")" : "")}}</p>
                            </div>
                        </div>
                    </it-button>
                </it-tooltip>
            </div>
            <div v-if="categoryParent.id != category.id" style="display: flex; flex-direction: row; margin: 16px;">
                <it-button v-if="categoryParent.childern.length != 0" @click="categoryParent.show_selector_child_categories = !categoryParent.show_selector_child_categories" :type="categoryParent.show_selector_child_categories ? 'primary' : 'neutral'">{{categoryParent.show_selector_child_categories ? "Hide" : "Show"}}</it-button>
                <CategoryParentSelector
                    v-if="categoryParent.show_selector_child_categories"
                    :global="global"
                    v-model="categoryObject.new_parent"
                    :categories="categoryParent.childern"
                    :category="category"
                ></CategoryParentSelector>
            </div>
            <!-- Spot Divider --> <it-divider v-if="categoryParentIndex != lastCategoryIndex" />
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoryParentSelector",
    props: {
        global: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Object,
            required: true,
        },
        newCategory: {
            type: Boolean,
            required: false,
            default: false,
        },
        allNewCategories: {
            type: Array,
            required: false,
            default: () => [],
        },
        allLinkedCategories: {
            type: Array,
            required: true,
        },
        newCategories: {
            type: Array,
            required: false,
            default: () => [],
        },
        categories: {
            type: Array,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            globalObject: this.global,
            allNewCategoriesArray: this.allNewCategories,
            allLinkedCategoriesArray: this.allLinkedCategories,
            categoryObject: this.category,
        };
    },
    computed: {
        lastNewCategoryIndex: {
            get(){
                return (this.categories.length - 1);
            }
        },
        lastCategoryIndex: {
            get(){
                return (this.categories.length - 1);
            }
        },
    },
    methods: {
        moveCategory(category, index){
            const categories = category.new_parent == null ? this.globalObject.categories : category.new_parent.childern;

            const displacement = index - category.new_sequence;
            const displacementDirection = displacement < 0 ? -1 : 1;
            const categoryIndexLimit = index + displacementDirection;

            if(displacement < 0){
                for(let categoryIndex = category.new_sequence + displacementDirection; categoryIndexLimit < categoryIndex; categoryIndex += displacementDirection){
                    categories[categoryIndex - displacementDirection] = categories[categoryIndex];
                }
            } else {
                for(let categoryIndex = category.new_sequence + displacementDirection; categoryIndex < categoryIndexLimit; categoryIndex += displacementDirection){
                    categories[categoryIndex - displacementDirection] = categories[categoryIndex];
                }
            }

            categories[index] = category;
            category.new_sequence = index;
        },
        showParentCategories(parentCategory){
            if(parentCategory != null){
                parentCategory.show_child_categories = true;
                parentCategory.show_info = true;
                this.showParentCategories(parentCategory.new_parent);
            }
        },
        selectCategory(selectedCategory) {
            if(this.newCategory){
                if(this.category.new_parent != null){
                    const newCategories = this.category.new_parent.new_childern.filter(filterCategory => filterCategory != this.category);
                    this.categoryObject.new_parent.new_childern.length = 0;
                    this.categoryObject.new_parent.new_childern.push(...newCategories);
                } else {
                    const newCategories = this.allNewCategories.filter(filterCategory => filterCategory != this.category);
                    this.allNewCategoriesArray.length = 0;
                    this.allNewCategoriesArray.push(...newCategories);
                }

                this.$emit("update:modelValue", selectedCategory);

                this.categoryObject.new_parent.new_childern.unshift(this.category);
                this.showParentCategories(this.categoryObject.new_parent);

                this.categoryObject.selecting_parent = false;
            } else {
                if(this.category.new_parent == null){
                    const categories = this.globalObject.categories.filter(filterCategory => filterCategory != this.category);
                    this.globalObject.categories.length = 0;
                    this.globalObject.categories.push(...categories);

                    {
                        const categoryCount = this.globalObject.categories.length;
                        for(let categoryIndex = this.category.new_sequence; categoryIndex != categoryCount; ++categoryIndex){
                            --this.globalObject.categories[categoryIndex].new_sequence;
                        }
                    }

                    if(this.category.parent == null){
                        this.allLinkedCategoriesArray.unshift(this.category);
                    }
                } else {
                    const categories = this.category.new_parent.childern.filter(filterCategory => filterCategory != this.category);
                    this.categoryObject.new_parent.childern.length = 0;
                    this.categoryObject.new_parent.childern.push(...categories);

                    {
                        const categoryCount = this.categoryObject.new_parent.childern.length;
                        for(let categoryIndex = this.category.new_sequence; categoryIndex != categoryCount; ++categoryIndex){
                            --this.categoryObject.new_parent.childern[categoryIndex].new_sequence;
                        }
                    }

                    if(this.category.parent == this.category.new_parent){
                        this.categoryObject.parent.linked_childern.unshift(this.category);
                    }
                }

                this.$emit("update:modelValue", selectedCategory);

                {
                    const categoryCount = this.categoryObject.new_parent.childern.length;
                    for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                        ++this.categoryObject.new_parent.childern[categoryIndex].new_sequence;
                    }
                }

                this.categoryObject.new_parent.childern.unshift(this.category);

                if(this.category.parent == this.category.new_parent){
                    const categories = this.category.parent.linked_childern.filter(filterCategory => filterCategory != this.category);
                    this.categoryObject.parent.linked_childern.length = 0;
                    this.categoryObject.parent.linked_childern.push(...categories);

                    if(this.category.sequence != 0){
                        this.categoryObject.new_sequence = 0;
                        this.moveCategory(this.category, this.category.sequence);
                    }
                    this.categoryObject.new_sequence = this.category.sequence;
                } else {
                    this.categoryObject.new_sequence = 0;
                }

                this.showParentCategories(this.categoryObject.new_parent);

                this.categoryObject.selecting_parent = false;
            }
        },
    }
}
</script>

<style scoped>

</style>