<template>
  <div style="margin: 16px">
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px;
      "
    >
      <it-input
        status="success"
        style="
          text-align: left;
          margin: 0px;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1em;
        "
        prefix="Spot Search"
        v-model="spotSearchQuery"
        placeholder="Spot name"
        mask
      />
      <it-button
        :loading="searching"
        :disabled="
          searching ||
          spotSearchQuery == spotSearchResultsSearchQuery ||
          spotSearchQuery == ''
        "
        @click="searchSpots()"
        style="margin: 4px"
        type="neutral"
        icon="search"
        round
      />
    </div>

    <it-divider />
  </div>

  <div
    style="margin: 32px"
    v-for="(spotSearchResult, spotSearchResultIndex) in spotSearchResults"
    :key="spotSearchResultIndex"
  >
    <div style="display: flex; flex-direction: row; flex: 1">
      <div style="display: flex; flex-direction: column; flex: 2">
        <!-- Media Carousel -->
        <it-tabs box style="margin: 0px; width: 270px">
          <it-tab
            v-if="spotSearchResult.thumbnail != null"
            title="thumbnail"
            key="thumbnail"
          >
            <img
              loading="lazy"
              style="
                display: block;
                margin: auto;
                width: 270px;
                height: 480px;
                object-fit: contain;
              "
              :src="spotSearchResult.thumbnail.url + global.googleKey"
              alt="Failed to load"
            />
          </it-tab>
          <it-tab
            v-for="(photo, photoIndex) in spotSearchResult.photos"
            :key="photoIndex"
            :title="photoIndex.toString()"
          >
            <img
              loading="lazy"
              style="
                display: block;
                margin: auto;
                width: 270px;
                height: 480px;
                object-fit: contain;
              "
              :src="photo.url + global.googleKey"
              alt="Failed to load"
            />
          </it-tab>
        </it-tabs>
      </div>
      <div style="display: flex; flex-direction: column; flex: 3; margin: 0px">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-input
            :status="
              spotSearchResult.new_name != spotSearchResult.name
                ? 'danger'
                : 'neutral'
            "
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1em;
            "
            prefix="Name"
            v-model="spotSearchResult.new_name"
            :placeholder="spotSearchResult.name"
          />
          <it-button
            v-show="spotSearchResult.new_name != spotSearchResult.name"
            @click="spotSearchResult.new_name = spotSearchResult.name"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 95px;
          "
        >
          <it-button
            @click="
              showMore[spotSearchResult.external_id] =
                !showMore[spotSearchResult.external_id]
            "
            style="margin-top: 8px; margin-bottom: 8px"
            type="neutral"
            block
            >{{
              showMore[spotSearchResult.external_id] ? "less ^" : "more ˅"
            }}</it-button
          >
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-input
            :status="
              spotSearchResult.new_external_id != spotSearchResult.external_id
                ? 'danger'
                : 'neutral'
            "
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            prefix="Place ID"
            v-model="spotSearchResult.new_external_id"
            :placeholder="spotSearchResult.external_id"
          />
          <it-button
            v-show="
              spotSearchResult.new_external_id != spotSearchResult.external_id
            "
            @click="
              spotSearchResult.new_external_id = spotSearchResult.external_id
            "
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-input
            :status="
              spotSearchResult.new_address != spotSearchResult.address
                ? 'danger'
                : 'neutral'
            "
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            prefix="Address"
            v-model="spotSearchResult.new_address"
            :placeholder="spotSearchResult.address"
          />
          <it-button
            v-show="spotSearchResult.new_address != spotSearchResult.address"
            @click="spotSearchResult.new_address = spotSearchResult.address"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="
              spotSearchResult.new_lat != spotSearchResult.lat
                ? 'danger'
                : 'neutral'
            "
            style="max-height: 24px"
            filled
            >Latitude</it-tag
          >
          <it-number-input
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            v-model="spotSearchResult.new_lat"
            resize-on-write
            hide-controls
          />
          <it-button
            v-show="spotSearchResult.new_lat != spotSearchResult.lat"
            @click="spotSearchResult.new_lat = spotSearchResult.lat"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="
              spotSearchResult.new_lng != spotSearchResult.lng
                ? 'danger'
                : 'neutral'
            "
            style="max-height: 24px"
            filled
            >Longitude</it-tag
          >
          <it-number-input
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            v-model="spotSearchResult.new_lng"
            resize-on-write
            hide-controls
          />
          <it-button
            v-show="spotSearchResult.new_lng != spotSearchResult.lng"
            @click="spotSearchResult.new_lng = spotSearchResult.lng"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-checkbox
            :type="
              spotSearchResult.new_homepage != spotSearchResult.homepage
                ? 'danger'
                : 'success'
            "
            label="Homepage"
            v-model="spotSearchResult.new_homepage"
          />
          <it-button
            v-show="spotSearchResult.new_homepage != spotSearchResult.homepage"
            @click="spotSearchResult.new_homepage = spotSearchResult.homepage"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="
              spotSearchResult.new_start_date != spotSearchResult.start_date
                ? 'danger'
                : 'neutral'
            "
            style="max-height: 24px"
            filled
            >Start DateTime</it-tag
          >
          <DatePicker v-model="spotSearchResult.new_start_date"></DatePicker>
          <it-button
            v-show="
              spotSearchResult.new_start_date != spotSearchResult.start_date
            "
            @click="
              spotSearchResult.new_start_date = spotSearchResult.start_date
            "
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spotSearchResult.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="
              spotSearchResult.new_end_date != spotSearchResult.end_date
                ? 'danger'
                : 'neutral'
            "
            style="max-height: 24px"
            filled
            >End DateTime</it-tag
          >
          <DatePicker v-model="spotSearchResult.new_end_date"></DatePicker>
          <it-button
            v-show="spotSearchResult.new_end_date != spotSearchResult.end_date"
            @click="spotSearchResult.new_end_date = spotSearchResult.end_date"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-select
            label-top="approved = show in app, everything else doesn't show in app"
            placement="bottom"
            :options="spotStatuses"
            v-model="spotSearchResult.new_status"
            divided
          />
          <it-button
            v-show="spotSearchResult.new_status != spotSearchResult.status"
            @click="spotSearchResult.new_status = spotSearchResult.status"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-checkbox
            :type="
              spotSearchResult.new_is_flagged != spotSearchResult.is_flagged
                ? 'danger'
                : 'success'
            "
            label="Flagged"
            v-model="spotSearchResult.new_is_flagged"
          />
          <it-button
            v-show="
              spotSearchResult.new_is_flagged != spotSearchResult.is_flagged
            "
            @click="
              spotSearchResult.new_is_flagged = spotSearchResult.is_flagged
            "
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="display: flex; flex-direction: column; border: solid 1px"
          v-for="(media, mediaIndex) in spotSearchResult.media_to_add"
          :key="mediaIndex"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 8px;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="TikTok/Instagram Link"
                v-model="media.media_link"
                @input="(e) => handleLink(e, media)"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Social Handle"
                v-model="media.handle"
              />
              <it-tooltip :content="media.handle + ' Link'" placement="top">
                <it-button
                  @click="openSelectedSocialMedia(spot)"
                  style="margin: 8px"
                  type="neutral"
                  icon="open_in_new"
                  round
                />
              </it-tooltip>
            </div>
          </div>

          <div style="display: flex; flex-direction: row; align-items: center">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 8px;
              "
            >
              <it-select
                placement="bottom"
                placeholder="Select a Social Platform"
                :options="socialMediaPlatforms"
                v-model="media.platform"
                style="margin: 4px"
                divided
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approval Comment"
                v-model="media.approvalComment"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approver"
                v-model="media.approver"
              />
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-button
            v-if="spotSearchResult.submitting_media"
            style="margin: 8px"
            block
            disabled
            loading
            >Submitting</it-button
          >
          <it-button
            @click="spotSearchResult.media_to_add.push({})"
            type="neutral"
            block
            >Add Another Media</it-button
          >
        </div>

        <div style="display: flex; flex-direction: row; align-items: center">
          <it-button
            @click="spotSearchResult.assigning_categories = true"
            style="margin-top: 8px; margin-bottom: 8px"
            type="neutral"
            block
            >{{
              spotSearchResult.new_categories.length != 0
                ? "Assign or Unassign Categories"
                : "Assign Categories"
            }}</it-button
          >
          <it-button
            v-if="assignedCategoriesChanged(spotSearchResult)"
            @click="revertAssignedCategories(spotSearchResult)"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />

          <div v-if="spotSearchResult.assigning_categories">
            <it-modal
              v-model="spotSearchResult.assigning_categories"
              width="1000px"
            >
              <template #body>
                <SpotCategoryAssigner
                  :global="global"
                  v-model="spotSearchResult.assigning_categories"
                  :categories="globalObject.categories"
                  :spot="spotSearchResult"
                ></SpotCategoryAssigner>
              </template>
            </it-modal>
          </div>
        </div>

        <it-collapse>
          <it-collapse-item title="Categories">
            <div
              v-for="(
                category, categoryIndex
              ) in spotSearchResult.new_categories"
              :key="categoryIndex"
              style="margin: 4px"
            >
              <it-tag
                :type="
                  spotSearchResult.categories.find(
                    (filterCategory) => filterCategory == category
                  ) != null &&
                  spotSearchResult.new_categories.find(
                    (filterCategory) => filterCategory == category
                  ) != null
                    ? 'success'
                    : spotSearchResult.categories.find(
                        (filterCategory) => filterCategory == category
                      ) != null ||
                      spotSearchResult.new_categories.find(
                        (filterCategory) => filterCategory == category
                      ) != null
                    ? 'danger'
                    : 'neutral'
                "
                closable
                @close="unassignCategory(spotSearchResult, category)"
                style="max-height: 24px"
                >{{ globalObject.getCategoryPath(category) }}</it-tag
              >
            </div>
          </it-collapse-item>
        </it-collapse>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px;
      "
    >
      <it-button
        v-if="spotSearchResult.saving"
        style="margin: 8px"
        block
        disabled
        loading
        >Saving</it-button
      >
      <it-button
        v-else-if="spotSearchResultNotInternalized(spotSearchResult)"
        @click="saveSpot(spotSearchResult)"
        type="danger"
        style="margin: 8px"
        block
        pulse
        >Save</it-button
      >
      <it-button
        v-else
        @click="showSpotInSpotManager(spotSearchResult)"
        type="neutral"
        style="margin: 8px"
        block
        >Show in Spot Manager</it-button
      >
    </div>
    <!-- Spot Divider -->
    <it-divider v-if="spotSearchResultIndex != lastSpotSearchResultIndex" />
  </div>
</template>

<script>
import SpotCategoryAssigner from "./SpotCategoryAssigner.vue";

export default {
  name: "SpotInternalizer",
  components: {
    SpotCategoryAssigner,
  },
  props: {
    global: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globalObject: this.global,
      spotSearchQuery: "",
      spotSearchResultsSearchQuery: "",
      searching: false,
      spotSearchResults: [],

      statusTypes: ["unapproved", "approved", "rejected", "closed"],

      showMore: {},
    };
  },
  computed: {
    lastSpotSearchResultIndex: {
      get() {
        return this.spotSearchResults.length - 1;
      },
    },
  },
  methods: {
    async handleLink(e, media) {
      if (!e.target.value) {
        media.handle = "";
        media.platform = "";
        media.downloadUrl = "";
        media.contentType = "";
        return;
      }

      const url = e.target.value;
      const requestURL =
        this.global.domain +
        this.global.instaEndpoint +
        `/${encodeURIComponent(url)}`;
      console.log("requestURL", requestURL);

      const res = await fetch(requestURL);
      const data = (await res.json()).data;

      console.log("data", data);
      ``;

      if (data) {
        media.handle = data.handler;
        media.platform = data.platform;
        media.downloadUrl = data.downloadUrl;
        media.contentType = data.contentType;
      } else {
        media.handle = "";
        media.platform = "";
        media.downloadUrl = "";
        media.contentType = "";
      }
    },
    searchSpots() {
      this.searching = true;
      this.spotSearchResultsSearchQuery = this.spotSearchQuery;

      this.spotSearchResults.length = 0;

      const requestURL =
        this.global.domain +
        this.global.spotSearchEndpoint +
        this.spotSearchResultsSearchQuery;
      const requestMethod = "GET";
      const requestIndex = this.globalObject.requestCount++;
      fetch(requestURL)
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotInternalizer.searchSpots(); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            this.spotSearchResults.push(...responseJSON.data);
            const spotSearchResultCount = this.spotSearchResults.length;
            for (
              let spotSearchResultIndex = 0;
              spotSearchResultIndex != spotSearchResultCount;
              ++spotSearchResultIndex
            ) {
              const spotSearchResult =
                this.spotSearchResults[spotSearchResultIndex];

              {
                if (spotSearchResult.category != null) {
                  spotSearchResult.other_categories = [
                    ...spotSearchResult.other_categories,
                    spotSearchResult.category,
                  ];
                }

                const categoryCount = spotSearchResult.other_categories.length;
                for (
                  let categoryIndex = 0;
                  categoryIndex != categoryCount;
                  ++categoryIndex
                ) {
                  const category = this.globalObject.getCategory(
                    this.globalObject.categories,
                    spotSearchResult.other_categories[categoryIndex].id
                  );
                  if (category != null) {
                    spotSearchResult.other_categories[categoryIndex] = category;
                  }
                }
              }

              this.spotSearchResults[spotSearchResultIndex] = {
                ...spotSearchResult,
                new_name: spotSearchResult.name,
                new_address: spotSearchResult.address,
                external_id: spotSearchResult.id,
                new_external_id: spotSearchResult.id,
                lat: spotSearchResult.coord.lat,
                lng: spotSearchResult.coord.lng,
                new_lat: spotSearchResult.coord.lat,
                new_lng: spotSearchResult.coord.lng,
                start_date: spotSearchResult.coord.start_date,
                end_date: spotSearchResult.coord.end_date,
                new_start_date: spotSearchResult.coord.start_date,
                new_end_date: spotSearchResult.coord.end_date,
                status: "approved",
                new_status: "approved",
                homepage: false,
                new_homepage: false,
                categories: [...spotSearchResult.other_categories],
                new_categories: [...spotSearchResult.other_categories],
                assigning_categories: false,
                saving: false,

                media_to_add: [{}],
              };
            }
          } else {
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to retrieve spots.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotInternalizer.searchSpots(); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }

          this.searching = false;
        });
    },
    unassignCategory(spotSearchResult, category) {
      const newCategories = spotSearchResult.new_categories.filter(
        (filterCategory) => filterCategory != category
      );
      spotSearchResult.new_categories.length = 0;
      spotSearchResult.new_categories.push(...newCategories);
    },
    assignedCategoriesChanged(spotSearchResult) {
      const categories = spotSearchResult.categories;
      const categoryCount = categories.length;
      const newCategories = spotSearchResult.new_categories;

      if (categoryCount != newCategories.length) {
        return true;
      }

      for (
        let categoryIndex = 0;
        categoryIndex != categoryCount;
        ++categoryIndex
      ) {
        const category = categories[categoryIndex];
        if (
          spotSearchResult.new_categories.find(
            (filterCategory) => filterCategory == category
          ) == null
        ) {
          return true;
        }
      }

      for (
        let categoryIndex = 0;
        categoryIndex != categoryCount;
        ++categoryIndex
      ) {
        const category = newCategories[categoryIndex];
        if (
          spotSearchResult.categories.find(
            (filterCategory) => filterCategory == category
          ) == null
        ) {
          return true;
        }
      }

      return false;
    },
    revertAssignedCategories(spotSearchResult) {
      spotSearchResult.new_categories.length = 0;
      spotSearchResult.new_categories.push(...spotSearchResult.categories);
    },
    spotSearchResultNotInternalized(spotSearchResult) {
      const spotCount = this.global.spots.length;
      for (let spotIndex = 0; spotIndex != spotCount; ++spotIndex) {
        if (
          spotSearchResult.external_id !=
          this.global.spots[spotIndex].external_id
        )
          continue;
        return false;
      }

      return true;
    },
    saveSpot(spotSearchResult) {
      spotSearchResult.saving = true;

      let categories = [];

      {
        const categoryCount = spotSearchResult.new_categories.length;
        for (
          let categoryIndex = 0;
          categoryIndex != categoryCount;
          ++categoryIndex
        ) {
          categories.push({
            id: spotSearchResult.new_categories[categoryIndex].id,
          });
        }
      }

      let payload = {
        external_id: spotSearchResult.new_external_id,
        name: spotSearchResult.new_name,
        address: spotSearchResult.new_address,
        lat: spotSearchResult.new_lat,
        lng: spotSearchResult.new_lng,
        start_date: spotSearchResult.new_start_date,
        end_date: spotSearchResult.new_end_date,
        status: spotSearchResult.new_status,
        homepage: spotSearchResult.new_homepage,
        categories: categories,
      };

      if (spotSearchResult.media_to_add.length) {
        const media_to_add = spotSearchResult.media_to_add.filter(
          (media) => media.media_link
        );

        payload = {
          ...payload,
          media: JSON.stringify(media_to_add),
        };
      }

      const payloadJSON = JSON.stringify(payload);
      const requestURL = this.global.domain + this.global.spotEndpoint;
      const requestMethod = "POST";
      const requestIndex = this.globalObject.requestCount++;
      if (this.requestPayloadDebugging) {
        this.$Message({ text: "Request Payload: " + payloadJSON });
      }
      this.globalObject.logs.push({
        type: "primary",
        title:
          "SpotInternalizer.saveSpot(" +
          spotSearchResult.external_id +
          "); - [" +
          requestIndex +
          "]" +
          requestMethod +
          "(Success) " +
          requestURL,
        body: "Request Payload: " + JSON.stringify(payload, null, "\t"),
      });
      fetch(requestURL, {
        method: requestMethod,
        headers: {
          "Content-Type": "application/json",
        },
        body: payloadJSON,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotInternalizer.saveSpot(" +
                spotSearchResult.external_id +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            {
              const categoryCount = responseJSON.data.categories.length;
              for (
                let categoryIndex = 0;
                categoryIndex != categoryCount;
                ++categoryIndex
              ) {
                const category = this.globalObject.getCategory(
                  this.globalObject.categories,
                  responseJSON.data.categories[categoryIndex].id
                );
                if (category != null) {
                  responseJSON.data.categories[categoryIndex] = category;
                }
              }
            }

            if (responseJSON.data.medium != null) {
              const mediaCount = responseJSON.data.medium.length;
              for (let mediaIndex = 0; mediaIndex != mediaCount; ++mediaIndex) {
                const media = responseJSON.data.medium[mediaIndex];
                responseJSON.data.medium[mediaIndex] = {
                  ...media,
                  new_source_platform: media.source_platform,
                  new_source_handle: media.source_handle,
                  new_approver: media.approver,
                  new_approval_comment: media.approval_comment,
                  saving_changes: false,
                  delete_confirmed: true,
                  deleting: false,
                  set_as_thumbnail_confirmed: true,
                  setting_as_thumbnail: false,
                  show: true,
                };
              }
            }

            this.globalObject.spots.push({
              ...responseJSON.data,
              medium:
                responseJSON.data.medium == null
                  ? []
                  : responseJSON.data.medium,
              flags:
                responseJSON.data.flags == null ? [] : responseJSON.data.flags,
              new_name: responseJSON.data.name,
              new_address: responseJSON.data.address,
              new_external_id: responseJSON.data.external_id,
              new_lat: responseJSON.data.lat,
              new_lng: responseJSON.data.lng,
              new_status: responseJSON.data.status,
              new_is_flagged: responseJSON.data.is_flagged,
              new_homepage: responseJSON.data.homepage,
              new_categories: [...responseJSON.data.categories],
              assigning_categories: false,
              saving_changes: false,
              delete_confirmed: true,
              deleting: false,

              selected_media_file: null,
              selected_media_source_platform: "",
              selected_media_source_handle: "",
              selected_media_approver: "",
              selected_media_approval_comment: "",
            });

            spotSearchResult.saving = false;
          } else {
            spotSearchResult.saving = false;
            if (!this.requestPayloadDebugging) {
              this.$Message.danger({
                duration: 10000,
                text: payloadJSON,
              });
            }
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to save '" + spotSearchResult.new_name + "' spot.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotInternalizer.saveSpot(" +
                spotSearchResult.external_id +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
    showSpotInSpotManager(spotSearchResult) {
      this.globalObject.spots.length = 0;
      this.globalObject.spots.push(
        ...this.global.spots.filter(
          (filterSpot) => spotSearchResult.external_id == filterSpot.external_id
        )
      );
    },
  },
};
</script>

<style scoped></style>
