<template>
    <div v-for="(log, logIndex) in global.logs" style="margin: 8px;" :key="logIndex">
        <it-collapse>
          <it-collapse-item :title="log.title">{{log.body}}</it-collapse-item>
        </it-collapse>
    </div>

    <!-- <it-alert v-for="(log, logIndex) in global.logs" style="margin: 8px;" :type="log.type" :title="log.title" :body="log.body" :key="logIndex"/> -->
</template>

<script>
export default {
    name: "LogsPanel",
    props: {
        global: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>

</style>