<template>
    <div style="display: flex; flex-direction: column; margin: 4px;">
        <div v-for="(categoryPairing, categoryPairingIndex) in categories" :key="categoryPairingIndex">
            <div style="margin: 4px;">
                <it-tooltip content="Select Pairing" placement="right">
                    <it-button @click="selectCategory(categoryPairing)" :type="categoryPairingsEqual(categoryPairing, categoryObject.default_pairing) ? 'success' : (categoryPairingsEqual(categoryPairing, categoryObject.new_default_pairing) ? 'danger' : 'neutral')" style="margin: 8px;">
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <it-tag style="max-height: 24px;">Pairing</it-tag>
                            <div style="margin: 8px; text-align: left;">
                                <p>ID: {{categoryPairing.id}}</p>
                                <p>Name: {{categoryPairing.new_name + (categoryPairing.new_name != categoryPairing.name ? (" (" + categoryPairing.name + ")") : "")}}</p>
                                <p>Keyword: {{categoryPairing.new_keyword + (categoryPairing.new_keyword != categoryPairing.keyword ? (" (" + categoryPairing.keyword + ")") : "")}}</p>
                                <p>Visible: {{(categoryPairing.new_display ? "Yes" : "No") + ((categoryPairing.new_display != (categoryPairing.display == "default")) ? " (" + (categoryPairing.display == "hidden" ? "No" : "Yes") + ")" : "")}}</p>
                                <p>Allow Google Results: {{(categoryPairing.new_source ? "Yes" : "No") + ((categoryPairing.new_source != (categoryPairing.source == "internal_and_google")) ? " (" + (categoryPairing.source == "internal_and_google" ? "Yes" : "No") + ")" : "")}}</p>
                            </div>
                        </div>
                    </it-button>
                </it-tooltip>
            </div>
            <div style="display: flex; flex-direction: row; margin: 16px;">
                <it-button v-if="categoryPairing.childern.length != 0" @click="categoryPairing.show_selector_child_categories = !categoryPairing.show_selector_child_categories" :type="categoryPairing.show_selector_child_categories ? 'primary' : 'neutral'">{{categoryPairing.show_selector_child_categories ? "Hide" : "Show"}}</it-button>
                <CategoryPairingSelector
                    v-if="categoryPairing.show_selector_child_categories"
                    :global="global"
                    v-model="categoryObject.new_default_pairing"
                    :categories="categoryPairing.childern"
                    :category="category"
                ></CategoryPairingSelector>
            </div>
            <!-- Spot Divider --> <it-divider v-if="categoryPairingIndex != lastCategoryIndex" />
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoryPairingSelector",
    props: {
        global: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Object,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            globalObject: this.global,
            categoryObject: this.category,
        };
    },
    computed: {
        lastCategoryIndex: {
            get(){
                return (this.categories.length - 1);
            }
        }
    },
    methods: {
        categoryPairingsEqual(categoryPairing0, categoryPairing1) {
            if((categoryPairing0 != null) && (categoryPairing1 != null)){
                return categoryPairing0.id == categoryPairing1.id;
            }
            return categoryPairing0 == categoryPairing1;
        },
        selectCategory(selectedCategory) {
            this.$emit("update:modelValue", selectedCategory);

            this.categoryObject.selecting_pairing = false;
        },
    }
}
</script>

<style scoped>

</style>