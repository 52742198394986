<template>
    <div style="margin: 32px;">
        <div style="display: flex; flex-direction: row; flex: 1;">
            <div style="display: flex; flex-direction: column; flex: 3; margin: 0px;">
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-input style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 1em;" prefix="Name" v-model="spot.name" :placeholder="spot.name" />
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                <it-input style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 0.75em; color: grey;" prefix="Place ID" v-model="spot.external_id" :placeholder="spot.external_id" />
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                <it-input style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 0.75em; color: grey;" prefix="Address" v-model="spot.address" :placeholder="spot.address" />
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-tag style="max-height: 24px;" filled>Latitude</it-tag>
                    <it-number-input style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 0.75em; color: grey;" v-model="spot.lat" resize-on-write hide-controls/>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-tag style="max-height: 24px;" filled>Longitude</it-tag>
                    <it-number-input style="text-align: left; margin: 0px; font-family: Arial, Helvetica, sans-serif; font-size: 0.75em; color: grey;" v-model="spot.lng" resize-on-write hide-controls/>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-checkbox label="Homepage" v-model="spot.homepage" />
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; margin: 4px;">
                    <it-select label-top="approved = show in app, everything else doesn't show in app" placement="bottom" :options="statusTypes" v-model="spot.status" divided/>
                </div>

                <div style="display: flex; flex-direction: row; align-items: center;">
                    <it-button @click="assigning_categories = true" style="margin-top: 8px; margin-bottom: 8px;" type="neutral" block>{{ spot.new_categories.length != 0 ? "Assign or Unassign Categories" : "Assign Categories" }}</it-button>

                    <div v-if="assigning_categories">
                        <it-modal v-model="assigning_categories" width="1000px">
                            <template #body>
                                <SpotCategoryAssigner
                                    :global="global"
                                    v-model="assigning_categories"
                                    :categories="globalObject.categories"
                                    :spot="spot"
                                ></SpotCategoryAssigner>
                            </template>
                        </it-modal>
                    </div>
                </div>

                <it-collapse>
                    <it-collapse-item title="Categories">
                        <div v-for="(category, categoryIndex) in spot.new_categories" :key="categoryIndex" style="margin: 4px;">
                           <it-tag closable @close="unassignCategory(category)" style="max-height: 24px;">{{globalObject.getCategoryPath(category)}}</it-tag>
                        </div>
                    </it-collapse-item>
                </it-collapse>
            </div>
        </div>

        <div style="display: flex; flex-direction: row; align-items: center; margin: 0px;">
            <it-button v-if="saving" style="margin: 8px;" block disabled loading>Saving</it-button>
            <it-button v-else-if="spotNotInternalized()" @click="saveSpot()" type="danger" style="margin: 8px;" block pulse>Save</it-button>
            <it-button v-else @click="showSpotInSpotManager()" type="neutral" style="margin: 8px;" block>Show in Spot Manager</it-button>
        </div>
    </div>
</template>

<script>
import SpotCategoryAssigner from "./SpotCategoryAssigner.vue";

export default {
    name: "SpotCreator",
    components: {
        SpotCategoryAssigner,
    },
    props: {
        global: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            globalObject: this.global,
            statusTypes: [
                "unapproved",
                "approved",
                "rejected",
                "closed",
            ],

            spot: {
                name: "",
                address: "",
                external_id: "",
                lat: "0.0",
                lng: "0.0",
                status: "approved",
                homepage: false,
                categories: [],
                new_categories: [],
            },
            
            assigning_categories: false,
            saving: false,
        };
    },
    methods: {
        unassignCategory(category){
            const newCategories = this.spot.new_categories.filter(filterCategory => filterCategory != category);
            this.spot.new_categories.length = 0;
            this.spot.new_categories.push(...newCategories);
        },
        spotNotInternalized() {
            const spotCount = this.global.spots.length;
            for(let spotIndex = 0; spotIndex != spotCount; ++spotIndex){
                if (this.spot.external_id != this.global.spots[spotIndex].external_id) continue;
                return false;
            }

            return true;
        },
        saveSpot() {
            this.saving = true;

            let categories = [];

            {
                const categoryCount = this.spot.new_categories.length;
                for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                    categories.push({
                        id: this.spot.new_categories[categoryIndex].id,
                    })
                }
            }

            let payload = {
                external_id: this.spot.external_id,
                name: this.spot.name,
                address: this.spot.address,
                lat: this.spot.lat,
                lng: this.spot.lng,
                status: this.spot.status,
                homepage: this.spot.homepage,
                categories: categories,
            };

            const payloadJSON = JSON.stringify(payload);
            const requestURL = this.global.domain + this.global.spotEndpoint;
            const requestMethod = "POST";
            const requestIndex = this.globalObject.requestCount++;
            if (this.requestPayloadDebugging) {
                this.$Message({ text: "Request Payload: " + payloadJSON });
            }
            this.globalObject.logs.push({
                type: "primary",
                title: "SpotInternalizer.saveSpot(" + this.spot.external_id + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                body: "Request Payload: " + JSON.stringify(payload, null, '\t'),
            });
            fetch(requestURL, {
                method: requestMethod,
                headers: {
                    "Content-Type": "application/json"
                },
                body: payloadJSON,
            }).then((response) => response.json()).then((responseJSON) => {
                if (responseJSON.success) {
                    if (this.global.responsePayloadDebugging) {
                        this.$Message({ text: "Response Payload: " + JSON.stringify(responseJSON) });
                    }
                    this.globalObject.logs.push({
                        type: "primary",
                        title: "SpotInternalizer.saveSpot(" + this.spot.external_id + "); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });

                    {
                        const categoryCount = responseJSON.data.categories.length;
                        for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                            const category = this.globalObject.getCategory(this.globalObject.categories, responseJSON.data.categories[categoryIndex].id);
                            if(category != null){
                                responseJSON.data.categories[categoryIndex] = category;
                            }
                        }
                    }

                    if(responseJSON.data.medium != null){
                        const mediaCount = responseJSON.data.medium.length;
                        for(let mediaIndex = 0; mediaIndex != mediaCount; ++mediaIndex){
                            const media = responseJSON.data.medium[mediaIndex];
                            responseJSON.data.medium[mediaIndex] = {
                                ...media,
                                new_source_platform: media.source_platform,
                                new_source_handle: media.source_handle,
                                new_approver: media.approver,
                                new_approval_comment: media.approval_comment,
                                saving_changes: false,
                                delete_confirmed: true,
                                deleting: false,
                                set_as_thumbnail_confirmed: true,
                                setting_as_thumbnail: false,
                                show: true,
                            };
                        }
                    }

                    this.globalObject.spots.push({
                        ...responseJSON.data,
                        medium: responseJSON.data.medium == null ? [] : responseJSON.data.medium,
                        flags: responseJSON.data.flags == null ? [] : responseJSON.data.flags,
                        new_name: responseJSON.data.name,
                        new_address: responseJSON.data.address,
                        new_external_id: responseJSON.data.external_id,
                        new_lat: responseJSON.data.lat,
                        new_lng: responseJSON.data.lng,
                        new_status: responseJSON.data.status,
                        new_is_flagged: responseJSON.data.is_flagged,
                        new_homepage: responseJSON.data.homepage,
                        new_categories: [...responseJSON.data.categories],
                        assigning_categories: false,
                        saving_changes: false,
                        delete_confirmed: true,
                        deleting: false,
                        
                        selected_media_file: null,
                        selected_media_source_platform: "",
                        selected_media_source_handle: "",
                        selected_media_approver: "",
                        selected_media_approval_comment: "",
                    });
                    
                    this.saving = false;
                }
                else {
                    this.saving = false;
                    if (!this.requestPayloadDebugging) {
                        this.$Message.danger({
                            duration: 10000,
                            text: payloadJSON
                        });
                    }
                    this.$Notification.danger({
                        duration: 10000,
                        title: "Failed to save '" + this.spot.name + "' spot.",
                        text: responseJSON.error.message
                    });
                    this.globalObject.logs.push({
                        type: "danger",
                        title: "SpotInternalizer.saveSpot(" + this.spot.external_id + "); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                        body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                    });
                }
            });
        },
        showSpotInSpotManager(){
            this.globalObject.spots.length = 0;
            this.globalObject.spots.push(...this.global.spots.filter((filterSpot) => this.spot.external_id == filterSpot.external_id));
        },
    },
}
</script>

<style scoped>

</style>