<template>
    <div style="display: flex; flex-direction: column; margin: 4px;">
        <div v-for="(category, categoryIndex) in categories" :key="categoryIndex">
            <div style="margin: 4px;">
                <it-tooltip content="Assign Category" placement="right">
                    <it-button @click="selectCategory(category)" :type="(spot.categories.find(filterCategory => filterCategory == category) != null) && (spot.new_categories.find(filterCategory => filterCategory == category) != null) ? 'success' : ((spot.categories.find(filterCategory => filterCategory == category) != null) || (spot.new_categories.find(filterCategory => filterCategory == category) != null) ? 'danger' : 'neutral')" style="margin: 8px;">
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <it-tag :type="spot.new_categories.find(filterCategory => filterCategory == category) != null ? 'success' : 'neutral'" style="max-height: 24px;" filled>{{spot.new_categories.find(filterCategory => filterCategory == category) != null ? "Assigned Category" : "Unassigned Category"}}</it-tag>
                            <div style="margin: 8px; text-align: left;">
                                <p>ID: {{category.id}}</p>
                                <p>Name: {{category.new_name + (category.new_name != category.name ? (" (" + category.name + ")") : "")}}</p>
                                <p>Keyword: {{category.new_keyword + (category.new_keyword != category.keyword ? (" (" + category.keyword + ")") : "")}}</p>
                                <p>Visible: {{(category.new_display ? "Yes" : "No") + ((category.new_display != (category.display == "default")) ? " (" + (category.display == "hidden" ? "No" : "Yes") + ")" : "")}}</p>
                                <p>Allow Google Results: {{(category.new_source ? "Yes" : "No") + ((category.new_source != (category.source == "internal_and_google")) ? " (" + (category.source == "internal_and_google" ? "Yes" : "No") + ")" : "")}}</p>
                            </div>
                        </div>
                    </it-button>
                </it-tooltip>
            </div>
            <div style="display: flex; flex-direction: row; margin: 16px;">
                <it-button v-if="category.childern.length != 0" @click="category.show_selector_child_categories = !category.show_selector_child_categories" :type="category.show_selector_child_categories ? 'primary' : 'neutral'">{{category.show_selector_child_categories ? "Hide" : "Show"}}</it-button>
                <SpotCategoryAssigner
                    v-if="category.show_selector_child_categories"
                    :global="global"
                    v-model="spotObject.assigning_categories"
                    :categories="category.childern"
                    :spot="spot"
                ></SpotCategoryAssigner>
            </div>
            <!-- Spot Divider --> <it-divider v-if="categoryIndex != lastCategoryIndex" />
        </div>
    </div>
</template>

<script>
export default {
    name: "SpotCategoryAssigner",
    props: {
        global: {
            type: Object,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        spot: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            globalObject: this.global,
            spotObject: this.spot,
        };
    },
    computed: {
        lastCategoryIndex: {
            get(){
                return (this.categories.length - 1);
            }
        }
    },
    methods: {
        selectCategory(selectedCategory) {
            if(this.spot.new_categories.find(filterCategory => filterCategory == selectedCategory) == null){
                this.spotObject.new_categories.push(selectedCategory);
            } else {
                const newCategories = this.spot.new_categories.filter(filterCategory => filterCategory != selectedCategory);
                this.spotObject.new_categories.length = 0;
                this.spotObject.new_categories.push(...newCategories);
            }
        },
    }
}
</script>

<style scoped>

</style>