<template>
    <CategoryEditor
        v-if="globalObject.categories.length != 0"
        :global="global"
        :showChildCategories="true"
        :allNewCategories="newCategories"
        :allLinkedCategories="linkedCategories"
        :parentCategory="null"
        :newCategories="newCategories"
        :categories="global.categories"
        :sections="sections"
        :linkedCategories="linkedCategories"
    ></CategoryEditor>
</template>

<script>
import CategoryEditor from "./CategoryEditor.vue";

export default {
    name: "CategoryManager",
    components: {
        CategoryEditor
    },
    props: {
        global: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            globalObject: this.global,
            newCategories: [],
            sections: [],
            linkedCategories: [],
        };
    },
    computed: {
        lastCategoryIndex: {
            get(){
                return (this.globalObject.categories.length - 1);
            }
        }
    },
    methods: {
        constructCategories(parentCategory, categories){
            const categoryCount = categories.length;
            for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                const category = categories[categoryIndex];
                
                const sectionCount = category.sections.length;
                for(let sectionIndex = 0; sectionIndex != sectionCount; ++sectionIndex){
                    const sectionDisplacement = category.sections[sectionIndex];
                    category.sections[sectionIndex] = {
                        displacement: sectionDisplacement,
                        new: false,
                        saving_changes: false,
                        delete_confirmed: true,
                        deleting: false,
                    };
                }

                category.sections.sort((section0, section1) => section0.displacement - section1.displacement);

                const newCategory = {
                    ...category,
                    parent: parentCategory,
                    childern: [],
                    linked_childern: [],
                    new_parent: parentCategory,
                    new_sequence: categoryIndex,
                    new_name: category.name,
                    new_keyword: category.keyword,
                    new_display: category.display == "default",
                    new_source: category.source == "internal_and_google",
                    new_default_pairing: category.default_pairing,
                    new_childern: [],
                    show_section: false,
                    show_info: false,
                    show_child_categories: false,
                    selecting_parent: false,
                    selecting_pairing: false,
                    show_selector_child_categories: false,
                    saving_changes: false,
                    delete_confirmed: true,
                    deleting: false,
                };

                newCategory.childern = this.constructCategories(newCategory, category.childern);

                categories[categoryIndex] = newCategory;
            }

            return categories;
        },
        assignPairings(categories){
            const categoryCount = categories.length;
            for(let categoryIndex = 0; categoryIndex != categoryCount; ++categoryIndex){
                const category = categories[categoryIndex];

                if(category.new_default_pairing != null){
                    const pairing = this.globalObject.getCategory(this.globalObject.categories, category.new_default_pairing.id);
                    if(pairing != null){
                        category.new_default_pairing = category.default_pairing = pairing;
                    }
                }

                this.assignPairings(category.childern);
            }
        },
    },
    created () {
        const requestURL = this.global.domain + this.global.categoryEndpoint;
        const requestMethod = "GET";
        const requestIndex = this.globalObject.requestCount++;

        fetch(requestURL).then((response) => response.json()).then((responseJSON) => {
            if(responseJSON.success) {
                if(this.global.responsePayloadDebugging){
                    this.$Message({text: "Response Payload: " + JSON.stringify(responseJSON)});
                }
                this.globalObject.logs.push({
                    type: "primary",
                    title: "CategoryManager.created(); - [" + requestIndex + "]" + requestMethod + "(Success) " + requestURL,
                    body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                });
                this.globalObject.categories.length = 0;
                this.globalObject.categories.push(...this.constructCategories(null, responseJSON.data));
                this.assignPairings(this.globalObject.categories);
            } else {
                this.$Notification.danger({
                    duration: 10000,
                    title: "Failed to retrieve categories.",
                    text: responseJSON.error.message
                });
                this.globalObject.logs.push({
                    type: "danger",
                    title: "CategoryManager.created(); - [" + requestIndex + "]" + requestMethod + "(Failed) " + requestURL,
                    body: "Response Payload: " + JSON.stringify(responseJSON, null, '\t'),
                });
            }

            this.globalObject.loadingCategories = false;
        });
        return {}
    },
}
</script>

<style scoped>

</style>