<template>
  <div style="margin: 16px">
    <it-input
      status="success"
      style="
        text-align: left;
        margin: 0px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1em;
      "
      prefix="Spot Search"
      v-model="spotSearchQuery"
      placeholder="Spot name"
      mask
    />

    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px;
      "
    >
      Status:
      <it-select
        placement="bottom"
        placeholder="all"
        :options="spotStatuses"
        v-model="spotSearchStatus"
        style="margin: 4px"
        divided
      />
      <it-button
        v-show="spotSearchStatus != null"
        @click="spotSearchStatus = null"
        style="margin: 4px"
        type="neutral"
        icon="clear"
        round
      />
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px;
      "
    >
      Type:
      <it-select
        placement="bottom"
        placeholder="all"
        :options="spotTypes"
        v-model="spotSearchType"
        style="margin: 4px"
        divided
      />
      <it-button
        v-show="spotSearchType != null"
        @click="spotSearchType = null"
        style="margin: 4px"
        type="neutral"
        icon="clear"
        round
      />
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px;
      "
    >
      <it-checkbox label="Flagged" v-model="spotSearchFlagged" />
      <it-button
        v-show="spotSearchFlagged"
        @click="spotSearchFlagged = false"
        style="margin: 4px"
        type="neutral"
        icon="clear"
        round
      />
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px;
      "
    >
      <it-tag
        :type="spotSearchPage != 0 ? 'danger' : 'neutral'"
        style="max-height: 24px"
        filled
        >Page</it-tag
      >
      <it-number-input
        style="
          text-align: left;
          margin: 0px;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 0.75em;
          color: grey;
        "
        v-model="spotSearchPage"
        resize-on-write
      />
      <it-button
        v-show="spotSearchPage != 0"
        @click="spotSearchPage = 0"
        style="margin: 8px"
        type="danger"
        icon="undo"
        round
      />
    </div>

    <it-divider />
  </div>

  <div
    style="margin: 32px"
    v-for="(spot, spotIndex) in global.spots"
    :key="spotIndex"
  >
    <div style="display: flex; flex-direction: row; flex: 1">
      <div style="display: flex; flex-direction: column; flex: 2">
        <!-- Media Carousel -->
        <it-tabs box style="margin: 0px; width: 270px">
          <it-tab
            v-for="(media, mediaIndex) in spot.medium"
            :key="mediaIndex"
            :title="mediaIndex.toString()"
          >
            <it-popover
              :disabled="media.delete_confirmed"
              style="margin-right: 8px"
            >
              <it-button
                @click="media.delete_confirmed = !media.delete_confirmed"
                type="warning"
                style="margin: 8px"
                block
                :disabled="media.deleting"
                :loading="media.deleting"
                >{{ media.deleting ? "Deleting" : "Delete" }}</it-button
              >
              <template #content>
                <div>
                  <p style="margin-bottom: 8px">Are you sure?</p>
                  <div style="display: flex; justify-content: flex-end">
                    <it-button
                      @click="media.delete_confirmed = true"
                      size="small"
                      >No</it-button
                    >
                    <it-button
                      @click="deleteSpotMedia(spot, mediaIndex)"
                      size="small"
                      style="margin-left: 8px"
                      type="danger"
                      >Yes</it-button
                    >
                  </div>
                </div>
              </template>
            </it-popover>

            <video
              v-if="media.media_type == 'video'"
              style="
                display: block;
                margin: auto;
                width: 270px;
                height: 480px;
                object-fit: contain;
              "
              controls
            >
              <source :src="media.reference_url" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>

            <div v-else-if="media.media_type == 'photo'">
              <it-popover
                :disabled="media.set_as_thumbnail_confirmed"
                style="margin-right: 8px"
              >
                <it-button
                  @click="
                    media.set_as_thumbnail_confirmed =
                      !media.set_as_thumbnail_confirmed
                  "
                  type="warning"
                  style="margin: 8px"
                  block
                  :disabled="media.primary || media.setting_as_thumbnail"
                  :loading="media.setting_as_thumbnail"
                  >{{
                    media.setting_as_thumbnail
                      ? "Setting as Thumbnail"
                      : "Set as Thumbnail"
                  }}</it-button
                >
                <template #content>
                  <div>
                    <p style="margin-bottom: 8px">Are you sure?</p>
                    <div style="display: flex; justify-content: flex-end">
                      <it-button
                        @click="media.set_as_thumbnail_confirmed = true"
                        size="small"
                        >No</it-button
                      >
                      <it-button
                        @click="setSpotThumbnail(spot, mediaIndex)"
                        size="small"
                        style="margin-left: 8px"
                        type="danger"
                        >Yes</it-button
                      >
                    </div>
                  </div>
                </template>
              </it-popover>

              <it-button
                v-if="media.show"
                @click="media.show = !media.show"
                type="neutral"
                text
              >
                <img
                  loading="lazy"
                  style="
                    display: block;
                    margin: auto;
                    width: 270px;
                    height: 480px;
                    object-fit: contain;
                  "
                  :src="media.reference_url"
                  alt="Failed to load"
                />
              </it-button>
              <it-button
                v-else
                @click="media.show = !media.show"
                type="neutral"
                style="
                  display: block;
                  margin: auto;
                  width: 270px;
                  height: 480px;
                  object-fit: contain;
                "
                text
                >Load Image</it-button
              >
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 8px;
              "
            >
              <it-select
                placement="bottom"
                placeholder="Select a Social Platform"
                :options="socialMediaPlatforms"
                v-model="media.new_source_platform"
                style="margin: 4px"
                divided
              />
              <it-button
                v-show="media.new_source_platform != media.source_platform"
                @click="media.new_source_platform = media.source_platform"
                style="margin: 4px"
                type="danger"
                icon="undo"
                round
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                :status="
                  media.new_source_handle != media.source_handle
                    ? 'danger'
                    : 'neutral'
                "
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Social Handle"
                v-model="media.new_source_handle"
                :placeholder="media.source_handle"
              />
              <it-tooltip content="Revert" placement="top">
                <it-button
                  v-show="media.new_source_handle != media.source_handle"
                  @click="media.new_source_handle = media.source_handle"
                  style="margin: 8px"
                  type="danger"
                  icon="undo"
                  round
                />
              </it-tooltip>
              <it-tooltip
                :content="'Test ' + media.new_source_platform + ' Link'"
                placement="top"
              >
                <it-button
                  @click="openSocialMedia(media)"
                  style="margin: 8px"
                  type="neutral"
                  icon="open_in_new"
                  round
                />
              </it-tooltip>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                :status="
                  media.new_approver != media.approver ? 'danger' : 'neutral'
                "
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approver"
                v-model="media.new_approver"
                :placeholder="media.approver"
              />
              <it-tooltip content="Revert" placement="top">
                <it-button
                  v-show="media.new_approver != media.approver"
                  @click="media.new_approver = media.approver"
                  style="margin: 8px"
                  type="danger"
                  icon="undo"
                  round
                />
              </it-tooltip>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                :status="
                  media.new_approval_comment != media.approval_comment
                    ? 'danger'
                    : 'neutral'
                "
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approval Comment"
                v-model="media.new_approval_comment"
                :placeholder="media.approval_comment"
              />
              <it-tooltip content="Revert" placement="top">
                <it-button
                  v-show="media.new_approval_comment != media.approval_comment"
                  @click="media.new_approval_comment = media.approval_comment"
                  style="margin: 8px"
                  type="danger"
                  icon="undo"
                  round
                />
              </it-tooltip>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-button
                v-if="media.saving_changes"
                style="margin: 8px"
                block
                disabled
                loading
                >Saving</it-button
              >
              <it-button
                v-else-if="mediaChanged(media)"
                @click="saveMedia(spot, mediaIndex)"
                type="danger"
                style="margin: 8px"
                block
                pulse
                >Save</it-button
              >
              <it-button v-else style="margin: 8px" block disabled
                >Saved</it-button
              >
            </div>
          </it-tab>
          <it-tab title="+" key="+">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 8px;
              "
            >
              <input
                @change="mediaSelectorChanged(spot)"
                type="file"
                accept="image/png, image/jpeg, video/mp4"
                :id="'mediaSelector' + spot.id"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 8px;
              "
            >
              <it-select
                placement="bottom"
                placeholder="Select a Social Platform"
                :options="socialMediaPlatforms"
                v-model="spot.selected_media_source_platform"
                style="margin: 4px"
                divided
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Social Handle"
                v-model="spot.selected_media_source_handle"
              />
              <it-tooltip
                :content="
                  'Test ' + spot.selected_media_source_platform + ' Link'
                "
                placement="top"
              >
                <it-button
                  @click="openSelectedSocialMedia(spot)"
                  style="margin: 8px"
                  type="neutral"
                  icon="open_in_new"
                  round
                />
              </it-tooltip>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approver"
                v-model="spot.selected_media_approver"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approval Comment"
                v-model="spot.selected_media_approval_comment"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-button
                v-if="spot.submitting_media"
                style="margin: 8px"
                block
                disabled
                loading
                >Submitting</it-button
              >
              <it-button
                v-else-if="spot.selected_media_file != null"
                @click="submitMedia(spot)"
                type="success"
                style="margin: 8px"
                block
                pulse
                >Submit</it-button
              >
              <it-button v-else style="margin: 8px" block disabled
                >No Media to Submit</it-button
              >
            </div>
          </it-tab>
        </it-tabs>
      </div>
      <div style="display: flex; flex-direction: column; flex: 3; margin: 0px">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-input
            :status="spot.new_name != spot.name ? 'danger' : 'neutral'"
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1em;
            "
            prefix="Name"
            v-model="spot.new_name"
            :placeholder="spot.name"
          />
          <it-button
            v-show="spot.new_name != spot.name"
            @click="spot.new_name = spot.name"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 95px;
          "
        >
          <it-button
            @click="showMore[spot.external_id] = !showMore[spot.external_id]"
            style="margin-top: 8px; margin-bottom: 8px"
            type="neutral"
            block
            >{{ showMore[spot.external_id] ? "less ^" : "more ˅" }}</it-button
          >
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-input
            :status="
              spot.new_external_id != spot.external_id ? 'danger' : 'neutral'
            "
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            prefix="Place ID"
            v-model="spot.new_external_id"
            :placeholder="spot.external_id"
          />
          <it-button
            v-show="spot.new_external_id != spot.external_id"
            @click="spot.new_external_id = spot.external_id"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-input
            :status="spot.new_address != spot.address ? 'danger' : 'neutral'"
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            prefix="Address"
            v-model="spot.new_address"
            :placeholder="spot.address"
          />
          <it-button
            v-show="spot.new_address != spot.address"
            @click="spot.new_address = spot.address"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="spot.new_lat != spot.lat ? 'danger' : 'neutral'"
            style="max-height: 24px"
            filled
            >Latitude</it-tag
          >
          <it-number-input
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            v-model="spot.new_lat"
            resize-on-write
            hide-controls
          />
          <it-button
            v-show="spot.new_lat != spot.lat"
            @click="spot.new_lat = spot.lat"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="spot.new_lng != spot.lng ? 'danger' : 'neutral'"
            style="max-height: 24px"
            filled
            >Longitude</it-tag
          >
          <it-number-input
            style="
              text-align: left;
              margin: 0px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 0.75em;
              color: grey;
            "
            v-model="spot.new_lng"
            resize-on-write
            hide-controls
          />
          <it-button
            v-show="spot.new_lng != spot.lng"
            @click="spot.new_lng = spot.lng"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-checkbox
            :type="spot.new_homepage != spot.homepage ? 'danger' : 'success'"
            label="Homepage"
            v-model="spot.new_homepage"
          />
          <it-button
            v-show="spot.new_homepage != spot.homepage"
            @click="spot.new_homepage = spot.homepage"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="
              spot.new_start_date != spot.start_date ? 'danger' : 'neutral'
            "
            style="max-height: 24px"
            filled
            >Start DateTime</it-tag
          >
          <DatePicker v-model="spot.new_start_date"></DatePicker>
          <it-button
            v-show="spot.new_start_date != spot.start_date"
            @click="spot.new_start_date = spot.start_date"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          v-if="showMore[spot.external_id]"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-tag
            :type="spot.new_end_date != spot.end_date ? 'danger' : 'neutral'"
            style="max-height: 24px"
            filled
            >End DateTime</it-tag
          >
          <DatePicker v-model="spot.new_end_date"></DatePicker>
          <it-button
            v-show="spot.new_end_date != spot.end_date"
            @click="spot.new_end_date = spot.end_date"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-select
            label-top="approved = show in app, everything else doesn't show in app"
            placement="bottom"
            :options="spotStatuses"
            v-model="spot.new_status"
            divided
          />
          <it-button
            v-show="spot.new_status != spot.status"
            @click="spot.new_status = spot.status"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-checkbox
            :type="
              spot.new_is_flagged != spot.is_flagged ? 'danger' : 'success'
            "
            label="Flagged"
            v-model="spot.new_is_flagged"
          />
          <it-button
            v-show="spot.new_is_flagged != spot.is_flagged"
            @click="spot.new_is_flagged = spot.is_flagged"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />
        </div>

        <div
          style="display: flex; flex-direction: column; border: solid 1px"
          v-for="(media, mediaIndex) in spot.media_to_add"
          :key="mediaIndex"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 8px;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="TikTok/Instagram Link"
                v-model="media.media_link"
                @input="(e) => handleLink(e, media)"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Social Handle"
                v-model="media.handle"
              />
              <it-tooltip :content="media.handle + ' Link'" placement="top">
                <it-button
                  @click="openSelectedSocialMedia(spot)"
                  style="margin: 8px"
                  type="neutral"
                  icon="open_in_new"
                  round
                />
              </it-tooltip>
            </div>
          </div>

          <div style="display: flex; flex-direction: row; align-items: center">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 8px;
              "
            >
              <it-select
                placement="bottom"
                placeholder="Select a Social Platform"
                :options="socialMediaPlatforms"
                v-model="media.platform"
                style="margin: 4px"
                divided
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approval Comment"
                v-model="media.approvalComment"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 4px;
              "
            >
              <it-input
                status="neutral"
                style="
                  text-align: left;
                  margin: 0px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 1em;
                "
                prefix="Approver"
                v-model="media.approver"
              />
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px;
          "
        >
          <it-button
            v-if="spot.submitting_media"
            style="margin: 8px"
            block
            disabled
            loading
            >Submitting</it-button
          >
          <it-button @click="spot.media_to_add.push({})" type="neutral" block
            >Add Another Media</it-button
          >
        </div>

        <it-collapse v-if="spot.flags.length != 0">
          <it-collapse-item title="Reasons for Flag">
            <div
              v-for="(flag, flagIndex) in spot.flags"
              :key="flagIndex"
              style="margin: 4px"
            >
              <it-tag style="max-height: 24px">{{ flag.reason }}</it-tag>
            </div>
          </it-collapse-item>
        </it-collapse>

        <div style="display: flex; flex-direction: row; align-items: center">
          <it-button
            @click="spot.assigning_categories = true"
            style="margin-top: 8px; margin-bottom: 8px"
            type="neutral"
            block
            >{{
              spot.new_categories.length != 0
                ? "Assign or Unassign Categories"
                : "Assign Categories"
            }}</it-button
          >
          <it-button
            v-if="assignedCategoriesChanged(spot)"
            @click="revertAssignedCategories(spot)"
            style="margin: 8px"
            type="danger"
            icon="undo"
            round
          />

          <div v-if="spot.assigning_categories">
            <it-modal v-model="spot.assigning_categories" width="1000px">
              <template #body>
                <SpotCategoryAssigner
                  :global="global"
                  v-model="spot.assigning_categories"
                  :categories="globalObject.categories"
                  :spot="spot"
                ></SpotCategoryAssigner>
              </template>
            </it-modal>
          </div>
        </div>

        <it-collapse>
          <it-collapse-item title="Categories">
            <div
              v-for="(category, categoryIndex) in spot.new_categories"
              :key="categoryIndex"
              style="margin: 4px"
            >
              <it-tag
                :type="
                  spot.categories.find(
                    (filterCategory) => filterCategory == category
                  ) != null &&
                  spot.new_categories.find(
                    (filterCategory) => filterCategory == category
                  ) != null
                    ? 'success'
                    : spot.categories.find(
                        (filterCategory) => filterCategory == category
                      ) != null ||
                      spot.new_categories.find(
                        (filterCategory) => filterCategory == category
                      ) != null
                    ? 'danger'
                    : 'neutral'
                "
                closable
                @close="unassignCategory(spot, category)"
                style="max-height: 24px"
                >{{ globalObject.getCategoryPath(category) }}</it-tag
              >
            </div>
          </it-collapse-item>
        </it-collapse>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px;
      "
    >
      <it-popover :disabled="spot.delete_confirmed" style="margin-right: 8px">
        <it-button
          @click="spot.delete_confirmed = !spot.delete_confirmed"
          type="warning"
          style="margin: 8px"
          block
          :disabled="spot.deleting"
          :loading="spot.deleting"
          >{{ spot.deleting ? "Deleting" : "Delete" }}</it-button
        >
        <template #content>
          <div>
            <p style="margin-bottom: 8px">Are you sure?</p>
            <div style="display: flex; justify-content: flex-end">
              <it-button @click="spot.delete_confirmed = true" size="small"
                >No</it-button
              >
              <it-button
                @click="deleteSpot(spot)"
                size="small"
                style="margin-left: 8px"
                type="danger"
                >Yes</it-button
              >
            </div>
          </div>
        </template>
      </it-popover>

      <it-button
        v-if="spot.saving_changes"
        style="margin: 8px"
        block
        disabled
        loading
        >Saving</it-button
      >
      <it-button
        v-else-if="spotChanged(spot)"
        @click="saveSpot(spot)"
        type="danger"
        style="margin: 8px"
        block
        pulse
        >Save</it-button
      >
      <it-button v-else style="margin: 8px" block disabled>Saved</it-button>
    </div>
    <!-- Spot Divider -->
    <it-divider v-if="spotIndex != lastSpotIndex" />
  </div>
</template>

<script>
// import { ref, onMounted } from "vue";
import SpotCategoryAssigner from "./SpotCategoryAssigner.vue";

// const currentSection = ref("");

// onMounted(() => {
//     const observer = new IntersectionObserver((entries) => {
//         entries.forEach((entry) => {
//             if(0 < entry.intersectionRatio){
//                 currentSection.value = entry.target.getAttribute("id");
//             }
//         });
//     });
//     document.querySelectorAll("article h2").forEach((section) => {
//         observer.observe(section);
//     });
// });

export default {
  name: "SpotManager",
  components: {
    SpotCategoryAssigner,
  },
  props: {
    global: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globalObject: this.global,
      spotSearchQuery: "",
      spotSearchStatus: null,
      spotSearchType: null,
      spotSearchFlagged: false,
      spotSearchPage: 0,

      spotStatuses: ["unapproved", "approved", "rejected", "closed"],
      spotTypes: ["repo", "google"],

      socialMediaPlatforms: ["tiktok", "instagram"],

      socialMediaURLs: [
        "https://www.tiktok.com/@",
        "https://www.instagram.com/",
      ],
      showMore: {},
    };
  },
  computed: {
    lastSpotIndex: {
      get() {
        return this.globalObject.spots.length - 1;
      },
    },
  },
  watch: {
    spotSearchQuery() {
      if (this.spotSearchPage == 0) this.searchSpots();
      else this.spotSearchPage = 0;
    },
    spotSearchStatus() {
      if (this.spotSearchPage == 0) this.searchSpots();
      else this.spotSearchPage = 0;
    },
    spotSearchType() {
      if (this.spotSearchPage == 0) this.searchSpots();
      else this.spotSearchPage = 0;
    },
    spotSearchFlagged() {
      if (this.spotSearchPage == 0) this.searchSpots();
      else this.spotSearchPage = 0;
    },
    spotSearchPage() {
      this.searchSpots();
    },
  },
  methods: {
    async handleLink(e, media) {
      if (!e.target.value) {
        media.handle = "";
        media.platform = "";
        media.downloadUrl = "";
        media.contentType = "";
        return;
      }

      const url = e.target.value;
      const requestURL =
        this.global.domain +
        this.global.instaEndpoint +
        `/${encodeURIComponent(url)}`;
      console.log("requestURL", requestURL);

      const res = await fetch(requestURL);
      const data = (await res.json()).data;

      console.log("data", data);
      ``;

      if (data) {
        media.handle = data.handler;
        media.platform = data.platform;
        media.downloadUrl = data.downloadUrl;
        media.contentType = data.contentType;
      } else {
        media.handle = "";
        media.platform = "";
        media.downloadUrl = "";
        media.contentType = "";
      }
    },
    searchSpots() {
      this.globalObject.loadingSpots = true;
      this.globalObject.spots.length = 0;

      const requestURL =
        this.global.domain +
        this.global.spotEndpoint +
        this.global.spotListEndpoint +
        "?per_page=" +
        this.global.pageSizeLimit +
        "&page=" +
        this.spotSearchPage +
        "&name_like=" +
        this.spotSearchQuery +
        (this.spotSearchFlagged ? "&flagged=true" : "") +
        (this.spotSearchStatus != null
          ? "&status=" + this.spotSearchStatus
          : "") +
        (this.spotSearchType != null ? "&type=" + this.spotSearchType : "");
      const requestMethod = "GET";
      const requestIndex = this.globalObject.requestCount++;
      fetch(requestURL)
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.searchSpots(); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            const spotCount = responseJSON.data.length;

            this.globalObject.waitForCategories(() => {
              for (let spotIndex = 0; spotIndex != spotCount; ++spotIndex) {
                const spot = responseJSON.data[spotIndex];

                {
                  const categoryCount = spot.categories.length;
                  for (
                    let categoryIndex = 0;
                    categoryIndex != categoryCount;
                    ++categoryIndex
                  ) {
                    const category = this.globalObject.getCategory(
                      this.globalObject.categories,
                      spot.categories[categoryIndex].id
                    );
                    if (category != null) {
                      spot.categories[categoryIndex] = category;
                    }
                  }
                }

                {
                  const mediaCount = spot.medium.length;
                  for (
                    let mediaIndex = 0;
                    mediaIndex != mediaCount;
                    ++mediaIndex
                  ) {
                    const media = spot.medium[mediaIndex];
                    spot.medium[mediaIndex] = {
                      ...media,
                      new_source_platform: media.source_platform,
                      new_source_handle: media.source_handle,
                      new_approver: media.approver,
                      new_approval_comment: media.approval_comment,
                      saving_changes: false,
                      delete_confirmed: true,
                      deleting: false,
                      set_as_thumbnail_confirmed: true,
                      setting_as_thumbnail: false,
                      show: true,
                    };
                  }
                }

                responseJSON.data[spotIndex] = {
                  ...spot,
                  new_name: spot.name,
                  new_address: spot.address,
                  new_external_id: spot.external_id,
                  new_lat: spot.lat,
                  new_lng: spot.lng,
                  new_start_date: spot.start_date,
                  new_end_date: spot.end_date,
                  new_status: spot.status,
                  new_is_flagged: spot.is_flagged,
                  new_homepage: spot.homepage,
                  new_categories: [...spot.categories],
                  assigning_categories: false,
                  saving_changes: false,
                  delete_confirmed: true,
                  deleting: false,

                  selected_media_file: null,
                  selected_media_source_platform: "",
                  selected_media_source_handle: "",
                  selected_media_approver: "",
                  selected_media_approval_comment: "",

                  media_to_add: [{}],
                };
              }

              this.globalObject.spots.push(...responseJSON.data);
            });
          } else {
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to retrieve spots.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.searchSpots(); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }

          this.globalObject.loadingSpots = false;
        });
    },
    mediaSelectorChanged(spot) {
      spot.selected_media_file = document.getElementById(
        "mediaSelector" + spot.id
      ).files[0];
    },
    unassignCategory(spot, category) {
      const newCategories = spot.new_categories.filter(
        (filterCategory) => filterCategory != category
      );
      spot.new_categories.length = 0;
      spot.new_categories.push(...newCategories);
    },
    assignedCategoriesChanged(spot) {
      const categories = spot.categories;
      const categoryCount = categories.length;
      const newCategories = spot.new_categories;

      if (categoryCount != newCategories.length) {
        return true;
      }

      for (
        let categoryIndex = 0;
        categoryIndex != categoryCount;
        ++categoryIndex
      ) {
        const category = categories[categoryIndex];
        if (
          spot.new_categories.find(
            (filterCategory) => filterCategory == category
          ) == null
        ) {
          return true;
        }
      }

      for (
        let categoryIndex = 0;
        categoryIndex != categoryCount;
        ++categoryIndex
      ) {
        const category = newCategories[categoryIndex];
        if (
          spot.categories.find(
            (filterCategory) => filterCategory == category
          ) == null
        ) {
          return true;
        }
      }

      return false;
    },
    revertAssignedCategories(spot) {
      spot.new_categories.length = 0;
      spot.new_categories.push(...spot.categories);
    },
    spotChanged(spot) {
      return (
        spot.new_name != spot.name ||
        spot.new_address != spot.address ||
        spot.new_external_id != spot.external_id ||
        spot.new_lat != spot.lat ||
        spot.new_lng != spot.lng ||
        spot.new_start_date != spot.start_date ||
        spot.new_end_date != spot.end_date ||
        spot.new_homepage != spot.homepage ||
        spot.new_status != spot.status ||
        spot.new_is_flagged != spot.is_flagged ||
        this.assignedCategoriesChanged(spot) ||
        (spot.media_to_add &&
          spot.media_to_add.filter((media) => media.media_link).length)
      );
    },
    mediaChanged(media) {
      return (
        media.new_source_platform != media.source_platform ||
        media.new_source_handle != media.source_handle ||
        media.new_approver != media.approver ||
        media.new_approval_comment != media.approval_comment
      );
    },
    openSelectedSocialMedia(spot) {
      window.open(
        (spot.selected_media_source_platform == "tiktok"
          ? this.socialMediaURLs[0]
          : this.socialMediaURLs[1]) + spot.selected_media_source_handle
      );
    },
    openSocialMedia(media) {
      window.open(
        (media.new_source_platform == "tiktok"
          ? this.socialMediaURLs[0]
          : this.socialMediaURLs[1]) + media.new_source_handle
      );
    },
    submitMedia(spot) {
      spot.submitting_media = true;

      const fileData = new FormData();
      fileData.append("files", spot.selected_media_file);

      const mediaType = spot.selected_media_file.type.split("/")[0];

      let requestURL =
        this.global.domain +
        this.global.spotEndpoint +
        "/" +
        spot.id +
        this.global.spotMediaEndpoint;

      if (mediaType == "video") {
        requestURL += "?type=video";
      } else if (mediaType == "image") {
        requestURL += "?type=photo";
      } else {
        spot.submitting_media = false;
        return;
      }

      requestURL +=
        "&platform=" +
        spot.selected_media_source_platform +
        "&handle=" +
        spot.selected_media_source_handle +
        "&approver=" +
        spot.selected_media_approver +
        "&approval_comment=" +
        spot.selected_media_approval_comment;
      const requestMethod = "POST";
      const requestIndex = this.globalObject.requestCount++;

      fetch(requestURL, {
        method: requestMethod,
        body: fileData,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }

            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.submitMedia(" +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            spot.medium.push({
              ...responseJSON.data,
              new_source_platform: responseJSON.data.source_platform,
              new_source_handle: responseJSON.data.source_handle,
              new_approver: responseJSON.data.approver,
              new_approval_comment: responseJSON.data.approval_comment,
              saving_changes: false,
              delete_confirmed: true,
              deleting: false,
              set_as_thumbnail_confirmed: true,
              setting_as_thumbnail: false,
              show: true,
            });

            spot.selected_media_file = null;
            spot.selected_media_source_platform = "";
            spot.selected_media_source_handle = "";
            spot.selected_media_approver = "";
            spot.selected_media_approval_comment = "";
            spot.submitting_media = false;
          } else {
            spot.submitting_media = false;

            this.$Notification.danger({
              duration: 10000,
              title: "Failed to submit media.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.submitMedia(" +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
    setSpotThumbnail(spot, mediaIndex) {
      const media = spot.medium[mediaIndex];
      media.set_as_thumbnail_confirmed = true;
      media.setting_as_thumbnail = true;
      const requestURL =
        this.global.domain +
        this.global.spotEndpoint +
        "/" +
        spot.id +
        this.global.spotThumbnailEndpoint +
        "/" +
        media.id;
      const requestMethod = "PUT";
      const requestIndex = this.globalObject.requestCount++;
      fetch(requestURL, {
        method: requestMethod,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.setSpotThumbnail(" +
                ", " +
                mediaIndex +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            media.primary = true;
            media.setting_as_thumbnail = false;
          } else {
            media.setting_as_thumbnail = false;
            this.$Notification.danger({
              duration: 10000,
              title:
                "Failed to set media as thumbnail for '" +
                spot.name +
                "' spot.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.setSpotThumbnail(" +
                ", " +
                mediaIndex +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
    saveMedia(spot, mediaIndex) {
      const media = spot.medium[mediaIndex];
      media.saving_changes = true;

      let payload = {};
      if (media.source_platform != media.new_source_platform) {
        payload = {
          ...payload,
          source_platform: media.new_source_platform,
        };
      }
      if (media.source_handle != media.new_source_handle) {
        payload = {
          ...payload,
          source_handle: media.new_source_handle,
        };
      }
      if (media.approver != media.new_approver) {
        payload = {
          ...payload,
          approver: media.new_approver,
        };
      }
      if (media.approval_comment != media.new_approval_comment) {
        payload = {
          ...payload,
          approval_comment: media.new_approval_comment,
        };
      }

      const payloadJSON = JSON.stringify(payload);
      const requestURL =
        this.global.domain +
        this.global.spotEndpoint +
        "/" +
        spot.id +
        this.global.spotMediaEndpoint +
        "/" +
        media.id;
      const requestMethod = "PUT";
      const requestIndex = this.globalObject.requestCount++;
      if (this.requestPayloadDebugging) {
        this.$Message({ text: "Request Payload: " + payloadJSON });
      }
      this.globalObject.logs.push({
        type: "primary",
        title:
          "SpotManager.saveMedia(" +
          ", " +
          mediaIndex +
          "); - [" +
          requestIndex +
          "]" +
          requestMethod +
          "(Success) " +
          requestURL,
        body: "Request Payload: " + JSON.stringify(payload, null, "\t"),
      });
      fetch(requestURL, {
        method: requestMethod,
        headers: {
          "Content-Type": "application/json",
        },
        body: payloadJSON,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.saveMedia(" +
                ", " +
                mediaIndex +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            spot.medium[mediaIndex] = {
              ...media,
              ...responseJSON.data, // CHANGE THIS: DON'T BLINDLY APPLY ALL CHANGES. THE DB COULD HAVE REJECTED SOME/ALL CHANGES
              new_source_platform: responseJSON.data.source_platform,
              new_source_handle: responseJSON.data.source_handle,
              new_approver: responseJSON.data.approver,
              new_approval_comment: responseJSON.data.approval_comment,
              saving_changes: false,
              delete_confirmed: true,
              set_as_thumbnail_confirmed: true,
              show: true,
            };
          } else {
            media.saving_changes = false;
            if (!this.requestPayloadDebugging) {
              this.$Message.danger({
                duration: 10000,
                text: payloadJSON,
              });
            }
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to save '" + media.id + "' media.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.saveMedia(" +
                ", " +
                mediaIndex +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
    deleteSpotMedia(spot, mediaIndex) {
      const media = spot.medium[mediaIndex];
      media.delete_confirmed = true;
      media.deleting = true;
      const requestURL =
        this.global.domain +
        this.global.spotEndpoint +
        "/" +
        spot.id +
        this.global.spotMediaEndpoint +
        "/" +
        media.id;
      const requestMethod = "DELETE";
      const requestIndex = this.globalObject.requestCount++;
      fetch(requestURL, {
        method: requestMethod,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.deleteSpotMedia(" +
                ", " +
                mediaIndex +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            const medium = spot.medium.filter(
              (filterMedia) => filterMedia != media
            );
            spot.medium.length = 0;
            spot.medium.push(...medium);
          } else {
            media.deleting = false;
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to delete media from '" + spot.name + "' spot.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.deleteSpotMedia(" +
                ", " +
                mediaIndex +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
    saveSpot(spot) {
      spot.saving_changes = true;
      let payload = {};
      if (spot.external_id != spot.new_external_id) {
        payload = {
          ...payload,
          external_id: spot.new_external_id,
        };
      }
      if (spot.name != spot.new_name) {
        payload = {
          ...payload,
          name: spot.new_name,
        };
      }
      if (spot.address != spot.new_address) {
        payload = {
          ...payload,
          address: spot.new_address,
        };
      }
      if (spot.lat != spot.new_lat) {
        payload = {
          ...payload,
          lat: spot.new_lat,
        };
      }
      if (spot.lng != spot.new_lng) {
        payload = {
          ...payload,
          lng: spot.new_lng,
        };
      }
      if (spot.start_date != spot.new_start_date) {
        payload = {
          ...payload,
          start_date: spot.new_start_date,
        };
      }
      if (spot.end_date != spot.new_end_date) {
        payload = {
          ...payload,
          end_date: spot.new_end_date,
        };
      }
      if (spot.status != spot.new_status) {
        payload = {
          ...payload,
          status: spot.new_status,
        };
      }
      if (spot.is_flagged != spot.new_is_flagged) {
        payload = {
          ...payload,
          is_flagged: spot.new_is_flagged,
        };
      }
      if (spot.homepage != spot.new_homepage) {
        payload = {
          ...payload,
          homepage: spot.new_homepage,
        };
      }

      if (spot.media_to_add.length) {
        const media_to_add = spot.media_to_add.filter(
          (media) => media.media_link
        );

        payload = {
          ...payload,
          media: JSON.stringify(media_to_add),
        };
      }

      let unassignCategories = [];
      {
        const categoryCount = spot.categories.length;
        for (
          let categoryIndex = 0;
          categoryIndex != categoryCount;
          ++categoryIndex
        ) {
          const category = spot.categories[categoryIndex];
          if (
            spot.new_categories.find(
              (filterCategory) => filterCategory == category
            ) == null
          ) {
            unassignCategories.push({
              id: category.id,
            });
          }
        }
      }

      let assignCategories = [];
      {
        const categoryCount = spot.new_categories.length;
        for (
          let categoryIndex = 0;
          categoryIndex != categoryCount;
          ++categoryIndex
        ) {
          const category = spot.new_categories[categoryIndex];
          if (
            spot.categories.find(
              (filterCategory) => filterCategory == category
            ) == null
          ) {
            assignCategories.push({
              id: category.id,
            });
          }
        }
      }

      if (unassignCategories.length != 0 || assignCategories.length != 0) {
        let patchCategories = {};

        if (unassignCategories.length != 0) {
          patchCategories = {
            ...patchCategories,
            remove: unassignCategories,
          };
        }

        if (assignCategories.length != 0) {
          patchCategories = {
            ...patchCategories,
            add: assignCategories,
          };
        }

        payload = {
          ...payload,
          patch: {
            categories: patchCategories,
          },
        };
      }

      const payloadJSON = JSON.stringify(payload);
      const requestURL =
        this.global.domain + this.global.spotEndpoint + "/" + spot.id;
      const requestMethod = "PUT";
      const requestIndex = this.globalObject.requestCount++;
      if (this.requestPayloadDebugging) {
        this.$Message({ text: "Request Payload: " + payloadJSON });
      }
      this.globalObject.logs.push({
        type: "primary",
        title:
          "SpotManager.saveSpot(" +
          "); - [" +
          requestIndex +
          "]" +
          requestMethod +
          "(Success) " +
          requestURL,
        body: "Request Payload: " + JSON.stringify(payload, null, "\t"),
      });
      fetch(requestURL, {
        method: requestMethod,
        headers: {
          "Content-Type": "application/json",
        },
        body: payloadJSON,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.saveSpot(" +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });

            {
              const categoryCount = responseJSON.data.categories.length;
              for (
                let categoryIndex = 0;
                categoryIndex != categoryCount;
                ++categoryIndex
              ) {
                const category = this.globalObject.getCategory(
                  this.globalObject.categories,
                  responseJSON.data.categories[categoryIndex].id
                );
                if (category != null) {
                  responseJSON.data.categories[categoryIndex] = category;
                }
              }
            }

            {
              const mediaCount = responseJSON.data.medium.length;
              for (let mediaIndex = 0; mediaIndex != mediaCount; ++mediaIndex) {
                const media = responseJSON.data.medium[mediaIndex];
                responseJSON.data.medium[mediaIndex] = {
                  ...media,
                  new_source_platform: media.source_platform,
                  new_source_handle: media.source_handle,
                  new_approver: media.approver,
                  new_approval_comment: media.approval_comment,
                  saving_changes: false,
                  delete_confirmed: true,
                  deleting: false,
                  set_as_thumbnail_confirmed: true,
                  setting_as_thumbnail: false,
                  show: true,
                };
              }
            }

            // this.globalObject.spots[spotIndex] = {
            //     ...spot,
            //     ...responseJSON.data, // CHANGE THIS: DON'T BLINDLY APPLY ALL CHANGES. THE DB COULD HAVE REJECTED SOME/ALL CHANGES
            //     saving_changes: false,
            //     delete_confirmed: true,
            //     deleting: false,
            // };

            this.searchSpots();
          } else {
            spot.saving_changes = false;
            if (!this.requestPayloadDebugging) {
              this.$Message.danger({
                duration: 10000,
                text: payloadJSON,
              });
            }
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to save '" + spot.new_name + "' spot.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.saveSpot(" +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
    deleteSpot(spot) {
      spot.delete_confirmed = true;
      spot.deleting = true;
      const requestURL =
        this.global.domain + this.global.spotEndpoint + "/" + spot.id;
      const requestMethod = "DELETE";
      const requestIndex = this.globalObject.requestCount++;
      fetch(requestURL, {
        method: requestMethod,
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.success) {
            if (this.global.responsePayloadDebugging) {
              this.$Message({
                text: "Response Payload: " + JSON.stringify(responseJSON),
              });
            }
            this.globalObject.logs.push({
              type: "primary",
              title:
                "SpotManager.deleteSpot(" +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Success) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
            const spots = this.globalObject.spots.filter(
              (filterSpot) => filterSpot != spot
            );
            this.globalObject.spots.length = 0;
            this.globalObject.spots.push(...spots);

            this.searchSpots();
          } else {
            spot.deleting = false;
            this.$Notification.danger({
              duration: 10000,
              title: "Failed to delete '" + spot.name + "' spot.",
              text: responseJSON.error.message,
            });
            this.globalObject.logs.push({
              type: "danger",
              title:
                "SpotManager.deleteSpot(" +
                "); - [" +
                requestIndex +
                "]" +
                requestMethod +
                "(Failed) " +
                requestURL,
              body:
                "Response Payload: " + JSON.stringify(responseJSON, null, "\t"),
            });
          }
        });
    },
  },
  created() {
    this.searchSpots();
    return {};
  },
};
</script>

<style scoped></style>
