<template>
  <div
    style="display: flex; flex-direction: row; align-items: center; margin: 4px"
  >
    <img
      src="./assets/motive.png"
      alt="Motive"
      style="margin: 8px; width: 128px"
    />
    <it-alert
      style="margin: 16px"
      type="primary"
      :title="'v' + global.currentbuildversion + ' built ' + getBuiltTimespan()"
    >
      <!-- Build version body (things can be placed in here) -->
    </it-alert>
  </div>

  <it-tabs vertical style="flex: 1; margin: 0px">
    <it-tab title="Spot Manager" :disabled="false"
      ><SpotManager :global="global"></SpotManager
    ></it-tab>
    <it-tab title="Spot Internalizer" :disabled="false"
      ><SpotInternalizer :global="global"></SpotInternalizer
    ></it-tab>
    <it-tab title="Spot Creator" :disabled="false"
      ><SpotCreator :global="global"></SpotCreator
    ></it-tab>
    <it-tab title="Category Manager" :disabled="false"
      ><CategoryManager :global="global"></CategoryManager
    ></it-tab>
    <it-tab title="Settings" :disabled="false"
      ><SettingsPanel :global="global"></SettingsPanel
    ></it-tab>
    <it-tab title="Logs Panel" :disabled="false"
      ><LogsPanel :global="global"></LogsPanel
    ></it-tab>
  </it-tabs>
</template>

<script>
import {
  buildversion,
  buildyear,
  buildmonth,
  buildday,
  buildhour,
  buildminute,
  buildsecond,
} from "./build.js";

import SpotManager from "./SpotManager/SpotManager.vue";
import SpotInternalizer from "./SpotManager/SpotInternalizer.vue";
import SpotCreator from "./SpotManager/SpotCreator.vue";
import CategoryManager from "./CategoryManager/CategoryManager.vue";
import SettingsPanel from "./SettingsPanel/SettingsPanel.vue";
import LogsPanel from "./LogsPanel/LogsPanel.vue";

export default {
  name: "MotivesPortal",
  components: {
    SpotManager,
    SpotInternalizer,
    SpotCreator,
    CategoryManager,
    SettingsPanel,
    LogsPanel,
  },
  data() {
    return {
      global: {
        // VVV Build Data VVV
        currentbuildversion: buildversion,
        // currentbuildtime: buildtime,
        // ^^^ Build Data ^^^

        // VVV Settings VVV
        requestPayloadDebugging: false,
        responsePayloadDebugging: false,
        showCategorySequences: false,
        pageSizeLimit: 10,
        minimumBlockPeriod: 100,
        // ^^^ Settings ^^^

        // VVV Constants VVV
        domain: "https://api.getmotive.app/v2/admin",
        // domain: "http://localhost:3456/v2/admin",
        spotEndpoint: "/spot",
        spotListEndpoint: "/list",
        spotMediaEndpoint: "/media",
        spotThumbnailEndpoint: "/thumbnail",
        spotSearchEndpoint: "/google/search?name=",
        categoryEndpoint: "/category",
        sectionEndpoint: "/section",
        instaEndpoint: "/social",

        googleKey: "&key=AIzaSyDQ5-tJYK2olownxh1yLoGfIfi3ypRZEms",
        // ^^^ Constants ^^^

        // VVV Logging VVV
        requestCount: 0,
        logs: [],
        // ^^^ Logging ^^^

        // VVV Data VVV
        categories: [],
        spots: [],
        // ^^^ Data ^^^

        // VVV Signals (asynchronous tasks) VVV
        loadingCategories: true,
        loadingSpots: true,
        // ^^^ Signals (asynchronous tasks) ^^^

        // VVV Functions VVV
        block(
          gate, // gate = function that returns true to block execution or false to continue execution
          period, // period = time between checks in milliseconds
          execution // execution = function that executes after gate has continued execution
        ) {
          if (gate()) {
            setTimeout(() => this.block(gate, period, execution), period);
          } else {
            execution();
          }
        },
        waitForCategories(execution) {
          this.block(
            () => this.loadingCategories,
            this.minimumBlockPeriod,
            execution
          );
        },
        waitForSpots(execution) {
          this.block(
            () => this.loadingSpots,
            this.minimumBlockPeriod,
            execution
          );
        },
        getCategory(categories, id) {
          const categoryCount = categories.length;
          for (
            let categoryIndex = 0;
            categoryIndex != categoryCount;
            ++categoryIndex
          ) {
            const category = categories[categoryIndex];
            if (category.id == id) {
              return category;
            }

            const retrievedCategory = this.getCategory(category.childern, id);
            if (retrievedCategory != null) {
              return retrievedCategory;
            }
          }
          return null;
        },
        getCategoryPath(category) {
          let categoryPath = category.name;
          while (category.parent != null) {
            category = category.parent;
            categoryPath = category.name + " -> " + categoryPath;
          }
          return categoryPath;
        },
        // ^^^ Functions ^^^
      },
    };
  },
  methods: {
    getBuiltTimespan() {
      const datetime = new Date();

      const yearTimespan = datetime.getUTCFullYear() - buildyear;
      const monthTimespan = 1 + datetime.getUTCMonth() - buildmonth;
      const dayTimespan = datetime.getUTCDate() - buildday;
      const hourTimespan = datetime.getUTCHours() - buildhour;
      const minuteTimespan = datetime.getUTCMinutes() - buildminute;
      const secondTimespan = datetime.getUTCSeconds() - buildsecond;

      if (yearTimespan != 0)
        return yearTimespan + " year" + (yearTimespan != 1 ? "s" : "") + " ago";
      else if (monthTimespan != 0)
        return (
          monthTimespan + " month" + (monthTimespan != 1 ? "s" : "") + " ago"
        );
      else if (dayTimespan != 0)
        return dayTimespan + " day" + (dayTimespan != 1 ? "s" : "") + " ago";
      else if (hourTimespan != 0)
        return hourTimespan + " hour" + (hourTimespan != 1 ? "s" : "") + " ago";
      else if (minuteTimespan != 0)
        return (
          minuteTimespan + " minute" + (minuteTimespan != 1 ? "s" : "") + " ago"
        );
      else if (secondTimespan != 0)
        return (
          secondTimespan + " second" + (secondTimespan != 1 ? "s" : "") + " ago"
        );

      return "just now";
    },
    showMessage(type) {
      switch (type) {
        case "success":
          this.$Message.success({ text: "Success message!" });
          break;
        case "danger":
          this.$Message.danger({ text: "Danger message!" });
          break;
        case "warning":
          this.$Message.warning({ text: "Success message!" });
          break;
        default:
          this.$Message({ text: "Primary message!" });
          break;
      }
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
